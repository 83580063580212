import Box from '@mui/material/Box';

import { VerifyResetOTP } from 'components/auth/verify-reset-otp';

const VerifyResetOTPPage = () => {
  return (
    <Box component='main'>
      <VerifyResetOTP />
    </Box>
  );
};

export default VerifyResetOTPPage;
