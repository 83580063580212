import { SortFilterType } from 'types/data';

export const sortValues: SortFilterType[] = [
  {
    label: 'Date Created',
    value: 'created_at',
  },
  {
    label: 'Partner Name',
    value: 'partner_name',
  },
];

export type CenterType = {
  id: number;
  image: string;
  name: string;
  activeRep: number;
  partner?: string;
  phone?: string;
  email?: string;
  address?: string;
  walletBalance?: number;
  creditLimit?: number;
  creditBalance?: number;
  totalRepresentatives?: number;
  totalActiveRepresentatives?: number;
  totalInactiveRepresentatives?: number;
};

export const centers: CenterType[] = [
  {
    id: 1,
    image: 'https://picsum.photos/200',
    name: 'DCC OWORONSHOKI',
    activeRep: 1,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 2,
    image: 'https://picsum.photos/200',
    name: 'DCC IKEJA',
    activeRep: 2,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 3,
    image: 'https://picsum.photos/200',
    name: 'SANDTEX STORE',
    activeRep: 3,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 4,
    image: 'https://picsum.photos/200',
    name: 'DCC IYANA OWORO',
    activeRep: 0,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 5,
    image: 'https://picsum.photos/200',
    name: 'DCC IYANA OWORO',
    activeRep: 0,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 6,
    image: 'https://picsum.photos/200',
    name: 'DCC GBAGADA',
    activeRep: 2,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 7,
    image: 'https://picsum.photos/200',
    name: 'DCC LEKKI',
    activeRep: 1,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 8,
    image: 'https://picsum.photos/200',
    name: 'DCC OPEBI',
    activeRep: 1,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
];

export const orderStatuses = ['Completed', 'Partly Delivered', 'Cancelled'];

export const sampleData = {
  getAllVendors: {
    pageInfo: {
      currentPage: 1,
      size: 50,
      totalItems: 2,
      __typename: 'Pages',
    },
    nodes: [
      {
        _id: '6501b44d025a47d5c46d546a',
        referenceId: 'VEN-AL-IBA-00002',
        vendorName: 'Bamise Olusegun',
        image: 'https://picsum.photos/300',
        vendorType: 'distributor',
        phoneNumber: '08067846661',
        state: 'Oyo',
        city: 'Ibadan Town',
        address: 'CMS building, 102 Agbeni road, Amunigun, Ibadan ',
        isActive: false,
        createdAt: new Date(),
        walletBalance: 200220002,
        creditLimit: 2000000022,
        creditBalance: 1230000,
        totalRepresentatives: 10,
        totalActiveRepresentatives: 5,
        totalInactiveRepresentatives: 5,
        __typename: 'Vendor',
      },
      {
        _id: '61e8ccab32287ce1678902ff',
        referenceId: 'VEN-AL-IBA-00001',
        vendorName: 'TestP_vendor',
        image: 'https://picsum.photos/300',
        vendorType: 'manufacturer',
        phoneNumber: '08111111199',
        state: 'Oyo State',
        city: 'Ibadan Town',
        address: 'test',
        isActive: true,
        createdAt: new Date(),
        walletBalance: 200220002,
        creditLimit: 2000000022,
        creditBalance: 1230000,
        totalRepresentatives: 10,
        totalActiveRepresentatives: 5,
        totalInactiveRepresentatives: 5,
        __typename: 'Vendor',
      },
    ],
    __typename: 'VendorResponse',
  },
};

export type PartnerType = {
  _id: string;
  referenceId: string;
  vendorName: string;
  image: string;
  vendorType: string;
  phoneNumber: string;
  state: string;
  city: string;
  address: string;
  isActive: boolean;
  walletBalance?: number;
  creditLimit?: number;
  creditBalance?: number;
  totalRepresentatives?: number;
  totalActiveRepresentatives?: number;
  totalInactiveRepresentatives?: number;
};
