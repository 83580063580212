import { Stack } from '@mui/material';
import React from 'react';

import { Card } from 'components/shared/card';
import { colors } from 'theme/color';

import { PartnerType } from 'types/data';
import { InfoLabel } from './info-label';

type Props = {
  partner: PartnerType;
};

export const PartnerInfo: React.FC<Props> = ({ partner }) => {
  return (
    <Card sx={{ marginBottom: 4 }}>
      <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
        {/* <img
          src={partner.image}
          alt='Partner'
          style={{
            width: '127.81px',
            height: '127.81px',
            borderRadius: '50%',
          }}
        /> */}
        <Card
          sx={{
            width: { xs: '100%', lg: '40%' },
            backgroundColor: colors.neutral.main,
            boxShadow: 'none !important',
          }}
        >
          <Stack spacing={1}>
            <InfoLabel label='Partner Name' value={partner.partner_name} />
            <InfoLabel label='Partner ID' value={partner.partner_id} />
            <InfoLabel
              label='Center'
              value={partner.centre_info?.map((item) => item.centre_name).join(', ')}
            />
          </Stack>
        </Card>
        <Card
          sx={{
            width: { xs: '100%', lg: '40%' },
            backgroundColor: colors.neutral.main,
            boxShadow: 'none !important',
          }}
        >
          <Stack spacing={1}>
            <InfoLabel label='Phone Number' value={partner.phone_number.join(', ')} />
            <InfoLabel label='Email Address' value={partner.email} />
            <InfoLabel
              label='Address'
              value={partner.centre_info ? partner.centre_info[0]?.centre_address : ''}
            />
          </Stack>
        </Card>
      </Stack>
    </Card>
  );
};
