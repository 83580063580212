import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { colors } from 'theme/color'

type Props={
    children:React.ReactNode
    title?:string
    description?:string
}

export const FormCard:React.FC<Props> = ({ children,title, description}) => {
    return (
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' minHeight='100vh'>
          <Card sx={{ width: '100%', maxWidth: 580, boxShadow: 0, border: `1px solid ${colors.neutral['mid']}` }}>
            <Box sx={{ padding: 8 }}>
            <Stack gap={2} alignItems='center' paddingX={3}>
                <Typography variant='h1'>{title}</Typography>
                <Typography variant='md' color={colors.black['mid']} textAlign='center'>
                  {description}</Typography>
            </Stack>
                {children}
            </Box>
        </Card>
        </Box>
    )
}

