import { Box } from '@mui/material';
import { AuditTrail } from 'components/audit-trail';

const AuditTrailPage = () => {
  return (
    <Box>
      <AuditTrail />
    </Box>
  );
};

export default AuditTrailPage;
