import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';

import { centers } from 'components/partners/data';
import { Button, FormInput } from 'components/shared';
import { Modal } from 'components/shared/modal';
import { FormMultiSelect } from 'components/shared/multi-select';
import { ActionMeta, MultiValue } from 'react-select';
import { CreatePartnerFieldType, CreatePartnerSchema } from 'schema/partner.schema';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const EditPartnerModal: React.FC<Props> = ({ open, setOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<CreatePartnerFieldType>({
    resolver: zodResolver(CreatePartnerSchema),
  });

  const onSubmit = async (data: CreatePartnerFieldType) => {
    console.log(data);
    alert('Updated');
    setOpen(false);
  };

  const setCenterValue = (newValue: MultiValue<any>, actionMeta: ActionMeta<any>) => {
    setValue(
      'center',
      newValue.map((item) => item.value)
    );
  };

  return (
    <Modal title='Edit Partner' open={open} setOpen={setOpen} maxWidth='sm'>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label='Store Name'
          error={errors?.name}
          required
          placeholder='Enter store name'
          {...register('name')}
        />
        <FormInput
          label='Email'
          error={errors?.email}
          required
          placeholder='Enter store email'
          {...register('email')}
        />
        <FormInput
          label='Add Representative Email'
          error={errors?.repEmail}
          required
          placeholder='Enter Representative Email'
          {...register('repEmail')}
        />
        <FormInput
          label='SAP Code'
          error={errors?.sapCode}
          required
          placeholder='Enter SAP code'
          {...register('sapCode')}
        />
        <FormMultiSelect
          {...register('center')}
          label='Centre'
          error={errors?.center}
          required
          placeholder='Assign a centre to this user'
          containerWidth='100%'
          value={getValues('center')}
          options={
            centers.map((item) => ({
              label: item.name,
              value: item.id.toString(),
            })) as any
          }
          onChange={setCenterValue}
          isMulti
        />

        <Button type='submit' style={{ marginTop: '50px' }}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};
