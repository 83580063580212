import { Dispatch, SetStateAction, useState } from 'react';

import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar-alt.svg';
import { colors } from 'theme/color';
import { formatDate } from 'utils/format-date';

import { DateFilterType } from 'types/data';
import { Button } from '../button';
import { DateModal } from './date-modal';

type Props = {
  dateState: DateFilterType;
  setDateState: Dispatch<SetStateAction<DateFilterType>>;
};

export const DatePicker = ({ dateState, setDateState }: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const convertDateToText = (date: Date | undefined) => {
    return date ? formatDate(date) : '-';
  };

  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        style={{
          width: 'fit-content',
          backgroundColor: colors.white,
          color: colors.black.main,
          border: `1px solid ${colors.secondary.mid}`,
          boxShadow: `0px 1px 2px 0px ${colors.secondary.rgba}`,
          fontWeight: 500,
          fontSize: 14,
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <CalendarIcon />
        {convertDateToText(dateState.startDate)} - {convertDateToText(dateState.endDate)}
      </Button>
      <DateModal
        open={openModal}
        setOpen={setOpenModal}
        dateState={dateState}
        setDateState={setDateState}
      />
    </>
  );
};
