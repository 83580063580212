import React, { ChangeEventHandler } from 'react';

import searchIcon from 'assets/svgs/search.svg';
import { FormInput } from 'components/shared';
import { colors } from 'theme/color';

type Props = {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  width?: string;
  disableMargin?: boolean;
};

export const SearchInput: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  width,
  disableMargin,
}) => {
  return (
    <FormInput
      value={value}
      onChange={onChange}
      style={{
        backgroundColor: colors.white,
        width: width || '45%',
      }}
      containerWidth='100%'
      disableMargin={disableMargin}
      placeholder={placeholder ?? 'Search anything....'}
      startAdornment={
        <img
          src={searchIcon}
          alt='Search'
          style={{
            marginRight: 10,
          }}
        />
      }
    />
  );
};
