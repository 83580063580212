import { Stack, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

import { orderStatuses } from 'components/centers/data';
import { FilterMenu } from 'components/filter-menu';
import { SearchInput } from 'components/search-input';
import { SampleTable } from 'components/table/sample';

export const DataOrders = () => {
  const [searchValue, setSearchValue] = useState('');
  const [orderFilter, setOrderFilter] = useState('');

  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const changeOrderFilter = (value: string) => {
    setOrderFilter(value);
  };
  return (
    <>
      <Typography variant='h3'>Purchased Products</Typography>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={1}
        useFlexGap
        mb={4}
      >
        <SearchInput value={searchValue} onChange={changeSearchValue} />
        <FilterMenu
          label='Sort:'
          labelValue='All Status'
          selectedValue={orderFilter}
          values={orderStatuses}
          onSelectValue={changeOrderFilter}
        />
      </Stack>
      <SampleTable />
    </>
  );
};
