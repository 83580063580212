export const sampleData = {
  getAllVendors: {
    pageInfo: {
      currentPage: 1,
      size: 50,
      totalItems: 2,
      __typename: 'Pages',
    },
    nodes: [
      {
        _id: '6501b44d025a47d5c46d546a',
        referenceId: 'VEN-AL-IBA-00002',
        vendorName: 'Bamise Olusegun',
        image: 'https://picsum.photos/300',
        vendorType: 'distributor',
        phoneNumber: '08067846661',
        state: 'Oyo',
        city: 'Ibadan Town',
        address: 'CMS building, 102 Agbeni road, Amunigun, Ibadan ',
        isActive: false,
        createdAt: new Date(),
        walletBalance: 200220002,
        creditLimit: 2000000022,
        creditBalance: 1230000,
        totalRepresentatives: 10,
        totalActiveRepresentatives: 5,
        totalInactiveRepresentatives: 5,
        __typename: 'Vendor',
      },
      {
        _id: '61e8ccab32287ce1678902ff',
        referenceId: 'VEN-AL-IBA-00001',
        vendorName: 'TestP_vendor',
        image: 'https://picsum.photos/300',
        vendorType: 'manufacturer',
        phoneNumber: '08111111199',
        state: 'Oyo State',
        city: 'Ibadan Town',
        address: 'test',
        isActive: true,
        createdAt: new Date(),
        walletBalance: 200220002,
        creditLimit: 2000000022,
        creditBalance: 1230000,
        totalRepresentatives: 10,
        totalActiveRepresentatives: 5,
        totalInactiveRepresentatives: 5,
        __typename: 'Vendor',
      },
    ],
    __typename: 'VendorResponse',
  },
};
