import { DataTable } from 'components/table';
import { Currency, DetailsLink } from 'components/table/cell-renderers';
import { RoutePath } from 'constants/routes-path';
import React from 'react';
import { CenterType } from 'types/data';
import { TableColumnType, TableRowType } from 'types/table';

type Props = {
  data: CenterType[];
};

export const PartnerCentersTable: React.FC<Props> = ({ data }) => {
  const columns: TableColumnType[] = [
    {
      id: 'center_id',
      title: 'S/N',
      cellRenderer: ({ index }) => <> {index + 1}</>,
    },

    {
      id: 'centre_name',
      title: 'Center name',
    },
    {
      id: 'centre_id',
      title: 'Center id',
    },
    {
      id: 'credit_limit',
      title: 'Credit limit',
      cellRenderer: ({ columnValue }) => <Currency columnValue={columnValue} />,
    },
    {
      id: 'credit_balance',
      title: 'Credit Balance',
      cellRenderer: ({ columnValue }) => <Currency columnValue={columnValue} />,
    },

    {
      id: 'id',
      title: 'ACTIONS',
      cellRenderer: ({ columnValue }) => (
        <DetailsLink destination={`${RoutePath.CENTERS}/${columnValue}`} />
      ),
    },
  ];
  return (
    <DataTable
      columns={columns}
      rows={data as unknown as TableRowType[]}
      totalItems={data.length}
      currentPage={1}
      onPageChange={() => null}
    />
  );
};
