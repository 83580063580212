import { ZodType, z } from 'zod';

export type CreateRepFieldType = {
  firstName: string;
  lastName: string;
  email: string;
  centerID: string;
};

export const CreateRepSchema: ZodType<CreateRepFieldType> = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  centerID: z.string(),
});
