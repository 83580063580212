import React, { Dispatch, SetStateAction } from 'react';

import { CenterType } from 'components/centers/data';
import { Tabs } from 'components/shared';

import { DataOrders } from './data-orders';
import { DataRepresentatives } from './data-representatives';

type Props = {
  center: CenterType;
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
};

export const DataSection: React.FC<Props> = ({ center, setTabIndex, tabIndex }) => {
  return (
    <>
      <Tabs
        labels={['Orders', 'Representative']}
        panels={[<DataOrders />, <DataRepresentatives />]}
        index={tabIndex}
        setIndex={setTabIndex}
      />
    </>
  );
};
