import { Box, Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import { colors } from 'theme/color';

import { AllowedStatuses, statuses } from '../data';

type Props = {
  selectedStatus: AllowedStatuses;
  setSelectedStatus: Dispatch<SetStateAction<AllowedStatuses>>;
};

export const StatusHandle: React.FC<Props> = ({ selectedStatus, setSelectedStatus }) => {
  const isSelectedStatus = (status: AllowedStatuses) => {
    return selectedStatus === status;
  };
  return (
    <Stack direction='row' spacing={3} useFlexGap>
      {statuses.map((item) => (
        <Box
          key={item.value}
          sx={{
            padding: 1,
            borderBottomWidth: '3px',
            borderBottomStyle: 'solid',
            borderBottomColor: isSelectedStatus(item.value)
              ? colors.primary.main
              : colors.secondary.faded,
            cursor: 'pointer',
            mb: 3,
            transition: 'all 0.5s',
          }}
          onClick={() => setSelectedStatus(item.value)}
        >
          <Typography
            variant='md'
            color={isSelectedStatus(item.value) ? colors.black.main : colors.black.light}
            fontWeight={isSelectedStatus(item.value) ? 600 : 400}
          >
            {item.label}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};
