export const getSortOptions = (sortField: string) => {
  const sortParts = sortField.split('_');
  const sortOrder = sortParts[0];
  const sortBy = sortParts.slice(1).join('_');
  return [sortOrder, sortBy];
};

export const formatQuantity = (quantity: string | number) =>
  quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatAmount = (amount: string | number, toWholeNumber?: boolean) => {
  if (Number.isNaN(amount)) return '';
  if (toWholeNumber) {
    const value = Math.round(Number(amount));
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  let formattedAmount = (+amount || 0).toFixed(3).toString();
  if (!formattedAmount.includes('.')) {
    formattedAmount = `${amount}.00`;
  }
  return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPhone = (phone: string = '') => {
  let formattedPhone = phone.replace('+234', '0');
  if (formattedPhone[0] !== '0' && formattedPhone.length === 10) {
    formattedPhone = `0${formattedPhone}`;
  }
  return `${phone.slice(0, 4)} ${phone.slice(4, 7)} ${phone.slice(7)}`;
};

export const formatTime = (timeString: Date) => {
  const timeString12hr = new Intl.DateTimeFormat('default', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  }).format(timeString);

  return timeString12hr;
};
