export const colors = {
    primary: {
        main: '#E87C2E',
        light: '#F4BE97',
        mid: '#FFF1E7',
        faded: "#FEF9F6"
    },
    secondary: {
        main: '#05142F',
        light: '#09204A',
        mid: '#ADC4D9',
        rgba: 'rgba(204, 204, 204, 0.3)',
        faded: '#EAF1FF',
        wooden: '#D5DBE5',
        xAxis: '#DCE3EB',
    },
    red: {
        main: '#EA4336',
         light: '#FDCAC6',
        faded: '#FDECEB'
    },
    black: {
        main: '#001928',
        light: '#696969',
        mid: '#92929D',
        faded: "#1A283380",
        plain:'#F3F3F3',
        wooden: '#667085',
        chart:'#1A1A27',
        disabled: "#E8EBEE"
    },
    green:{
        main:'#34A853',
        light: '#F2FCF5',
        faded: '#BCF2CB'
    },
    gray: {
        main: '#5976A1',
        mid: '#E9EDF5',
        light: '#374B58',
        plain:'#CCCCCC',
        faded: '#7E7E7E',
        chart: "#CDD1DE"
    },
    neutral: {
        main: '#F2F3F7',
        mid: '#E2E2E2',
        light: '#F1F4F8'
    },
    blue: {
        main: '#1150CC',
        light: '#EFF4FF',
        chart: "#C4D8FF"
    },
    orange: {
        main: '#FC7B00'
    },
    white: '#FFFFFF'
}

export const PasswordPolicyColor = (conditionMet: boolean, value:string) => {
    return (!value ? colors.black['mid'] : conditionMet ? colors.green['main'] : colors.red['main']);
}