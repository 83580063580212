import { Box, FormLabel, Input, InputProps, Typography } from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';

import { PasswordPolicy } from 'components/password-policy';
import { colors } from 'theme/color';

type InputType = Omit<InputProps, 'error'>;

type Props = InputType & {
  label?: string;
  required?: boolean;
  hasPolicy?: boolean;
  watchValue?: string;
  error?: FieldError;
  containerWidth?: string;
  disableMargin?: boolean;
};

export const FormInput = React.forwardRef(
  (
    {
      label,
      hasPolicy,
      watchValue,
      required = false,
      error,
      containerWidth = 'fit-contents',
      disableMargin = false,
      ...resProps
    }: Props,
    ref
  ) => {
    return (
      <Box marginTop={!disableMargin ? 2 : 0} width={containerWidth}>
        {label && (
          <FormLabel sx={{ fontWeight: 600, color: '' }} htmlFor={label}>
            {label}{' '}
            {required ? (
              <Box component='span' color={colors.red['main']}>
                *
              </Box>
            ) : null}
          </FormLabel>
        )}
        <Input
          ref={ref}
          disableUnderline
          sx={{
            marginTop: !disableMargin ? 1 : 0,
            minHeight: 45,
            paddingX: 2,
            paddingY: 1,
            border: `1px solid ${
              error?.message ? colors.red['main'] : colors.secondary['mid']
            }`,
            borderRadius: '8px',
            backgroundColor: colors.white,
          }}
          id={label}
          fullWidth
          {...resProps}
        />
        {error?.message && !hasPolicy && (
          <Typography variant='md' color={colors.red['main']}>
            {error?.message}
          </Typography>
        )}

        {/* PASSWORD POLICY */}
        {hasPolicy && <PasswordPolicy value={watchValue!} error={error?.message} />}
      </Box>
    );
  }
);
