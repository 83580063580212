import { Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { ReactComponent as InfoIcon } from 'assets/svgs/info-circle.svg';
import { Button } from 'components/shared';
import Modal from 'components/shared/modal';
import { RoutePath } from 'constants/routes-path';
import { DELETE_PARTNER } from 'graphql/mutations/partner.mutation';
import { useNavigate } from 'react-router-dom';
import { PartnerType } from 'types/data';
import { sendCatchFeedback, sendFeedback } from 'utils/feedback';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  partner: PartnerType;
};

export const DeletePartnerModal: React.FC<Props> = ({ open, setOpen, partner }) => {
  const [deletePartner, { loading, error }] = useMutation(DELETE_PARTNER);
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      await deletePartner({
        variables: {
          id: partner.id,
        },
      });
      sendFeedback('Partner Deleted', 'success');
      setOpen(false);
      navigate(RoutePath.PARTNERS, { replace: true });
    } catch (error) {
      sendCatchFeedback(error);
    }
  };

  useEffect(() => {
    if (error) {
      sendCatchFeedback(error);
    }
  }, [error]);

  if (!partner) return null;

  return (
    <Modal title='Partner Information' open={open} setOpen={setOpen} maxWidth='sm'>
      <Stack alignItems='center' gap='10px' marginBottom={10}>
        <InfoIcon />
        <Typography variant='h2' fontWeight={500}>
          Remove Partner
        </Typography>
        <Typography variant='lg'>Are you sure you want to remove this partner</Typography>
      </Stack>
      <Stack direction='row' gap='10px'>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          No, back
        </Button>
        <Button type='button' variant='outlined' onClick={onSubmit} loading={loading}>
          Yes, delete
        </Button>
      </Stack>
    </Modal>
  );
};

export default DeletePartnerModal;
