import { Stack } from '@mui/material';
import React from 'react';

import { CenterType } from 'components/centers/data';
import { Card } from 'components/shared/card';
import { colors } from 'theme/color';

import { InfoLabel } from './info-label';

type Props = {
  center: CenterType;
};

export const CenterInfo: React.FC<Props> = ({ center }) => {
  return (
    <Card sx={{ marginBottom: 4 }}>
      <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
        <Card
          sx={{
            width: { xs: '100%', lg: '40%' },
            backgroundColor: colors.neutral.main,
            boxShadow: 'none !important',
          }}
        >
          <Stack spacing={1}>
            <InfoLabel label='Center Name' value={center.name} />
            <InfoLabel label='Center ID' value={center.id} />
            <InfoLabel label='Partner' value={center.partner} />
          </Stack>
        </Card>
        <Card
          sx={{
            width: { xs: '100%', lg: '40%' },
            backgroundColor: colors.neutral.main,
            boxShadow: 'none !important',
          }}
        >
          <Stack spacing={1}>
            <InfoLabel label='Phone Number' value={center.phone} />
            <InfoLabel label='Email Address' value={center.email} />
            <InfoLabel label='Address' value={center.address} />
          </Stack>
        </Card>
      </Stack>
    </Card>
  );
};
