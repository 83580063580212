import { Stack } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import { PartnerType } from 'components/partners/data';
import { BalanceCard } from './balance-card';

type Props = {
  partner: PartnerType;
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
};

export const BalanceInfo: React.FC<Props> = ({ partner, setTabIndex, tabIndex }) => {
  return (
    <Stack
      mb={4}
      spacing={4}
      direction='row'
      justifyContent='stretch'
      flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
      useFlexGap
    >
      {tabIndex === 0 && (
        <>
          <BalanceCard label='Wallet Balance' value={partner.walletBalance} />
          <BalanceCard label='Credit Limit' value={partner.creditLimit} />
          <BalanceCard label='Credit Balance' value={partner.creditBalance} />
        </>
      )}
      {tabIndex === 1 && (
        <>
          <BalanceCard
            label='Total Performed Orders'
            value={partner.totalRepresentatives}
            currency={false}
          />
          <BalanceCard
            label='Total Rejected Orders'
            value={partner.totalActiveRepresentatives}
            currency={false}
          />
          <BalanceCard
            label='Total Cancelled Orders'
            value={partner.totalInactiveRepresentatives}
            currency={false}
          />
        </>
      )}
    </Stack>
  );
};
