import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
import { ReactComponent as PrintIcon } from 'assets/svgs/print.svg';
import { BackButton } from 'components/back-button';
import { Button, FormInput } from 'components/shared';
import { FormSelect } from 'components/shared/select';
import { StatusChip } from 'components/table/cell-renderers';

import { colors } from 'theme/color';
import { sampleData } from '../data';
import { DetailControl } from './detail-control';
import { DetailsTable } from './details-table';

export const OrderDetails = () => {
  let { id } = useParams();

  const orderDetails = useMemo(() => {
    if (id) {
      return sampleData.getAllVendors.nodes.find(
        (item) => item.referenceId === id
      ) as any;
    }
  }, [id]);

  if (!orderDetails) return <>Invalid Order</>;

  return (
    <Box>
      <BackButton />
      <Stack
        justifyContent='space-between'
        alignItems='center'
        useFlexGap
        spacing={2}
        direction='row'
        flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
        mb={4}
      >
        <Stack spacing={1}>
          <Stack spacing={2} direction='row' alignItems='center'>
            <Typography variant='h3' fontWeight={700}>
              {orderDetails.referenceId}
            </Typography>
            <StatusChip columnValue={true} />
          </Stack>
          <Stack direction='row' alignItems='center' spacing='4px'>
            <CalendarIcon width={20} />
            <Typography variant='md' color={colors.black.light}>
              {new Date(orderDetails.createdAt).toDateString()}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction='row'
          flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
          useFlexGap
          alignItems='center'
          spacing={2}
        >
          <FormSelect
            options={[{ label: 'All Platform', value: '' }]}
            marginTop={0}
            padding={0}
            // displayEmpty
            value=''
          />
          <Button
            style={{
              backgroundColor: colors.secondary.light,
              width: 'fit-content',
              height: 45,
            }}
          >
            <PrintIcon />
          </Button>
          <Button
            style={{
              height: 45,
              width: 81,
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      <DetailControl orderDetails={orderDetails} />
      <FormInput
        rows={4}
        multiline
        label='Note'
        placeholder='Type some notes as messages to send to this customer'
      />
      <DetailsTable />
    </Box>
  );
};
