import { CardProps, Card as MuiCard } from '@mui/material';
import React from 'react';

import { colors } from 'theme/color';

export const Card: React.FC<CardProps> = ({ children, ...rest }) => {
  return (
    <MuiCard
      style={{
        padding: '20px',
        borderRadius: '15px',
        boxShadow: `0px 4px 25px 0px ${colors.secondary.rgba}`,
        position: 'relative',
      }}
      {...rest}
    >
      {children}
    </MuiCard>
  );
};
