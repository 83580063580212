import { Box, LinearProgress, Stack } from '@mui/material';
import { ChangeEvent, lazy, useCallback, useEffect, useState } from 'react';

import { ReactComponent as AddIcon } from 'assets/svgs/add.svg';
import { FilterMenu } from 'components/filter-menu';
import { PageHeader } from 'components/page-header';
import { SearchInput } from 'components/search-input';
import { Button } from 'components/shared';
import { DatePicker } from 'components/shared/date-picker';
import { sortValues } from './data';

import { useLazyQuery } from '@apollo/client';
import { NoDataView } from 'components/shared/no-data-view';
import { GET_REPS } from 'graphql/queries/reps.queries';
import { useDebounce } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { DateFilterType, RepsType, SortFilterType } from 'types/data';
import { sendCatchFeedback } from 'utils/feedback';
import { formatDate } from 'utils/format-date';
import { RepsTable } from './table';

// Lazy load modal
const AddRepModal = lazy(() => import('./modals/add-rep-modal'));
const DeactivateRepModal = lazy(() => import('./modals/deactivate-rep-modal'));
const DeleteRepModal = lazy(() => import('./modals/delete-rep-modal'));
const EditRepModal = lazy(() => import('./modals/edit-rep-modal'));

export const AllReps = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryRepId = searchParams.get('id');

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedRep, setSelectedRep] = useState<string | undefined>(queryRepId || '');
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortFilter, setSortFilter] = useState<SortFilterType>(sortValues[0]);
  const [dateState, setDateState] = useState<DateFilterType>({
    startDate: undefined,
    endDate: undefined,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 1000);

  const [getReps, { data, loading, error, refetch }] = useLazyQuery(GET_REPS);

  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const changeSortFilter = useCallback((value: SortFilterType) => {
    setSortFilter(value);
  }, []);

  const openEditModal = (rep: RepsType) => {
    setEditModal(true);
    setSelectedRep(rep.id);

    setSearchParams({ id: rep.id });
  };

  const getData = async () => {
    return await getReps({
      variables: {
        page: currentPage,
        limit: size,
        search: debouncedSearchTerm,
        sortBy: sortFilter.value,
        sortOrder: 'DESC',
        filters: {
          ...(dateState.startDate && {
            created_at: {
              gte: formatDate(dateState.startDate, false),
              ...(dateState.endDate && {
                lte: formatDate(dateState.endDate, false),
              }),
            },
          }),
        },
      },
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchTerm,
    dateState.startDate,
    sortFilter.value,
    dateState.endDate,
    currentPage,
    size,
  ]);

  useEffect(() => {
    if (error) {
      sendCatchFeedback(error);
    }
  }, [error]);

  // Check for query id
  useEffect(() => {
    if (queryRepId) {
      setEditModal(true);
      setSelectedRep(queryRepId);
    }
  }, [queryRepId]);

  return (
    <Box>
      <PageHeader
        title='Representatives'
        subtitle='Overview of  representatives activities.'
        controls={
          <Button startIcon={<AddIcon />} onClick={() => setAddModal(true)}>
            Add Rep
          </Button>
        }
      />
      <Stack
        mb={4}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        useFlexGap
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <SearchInput value={searchValue} onChange={changeSearchValue} />
        <Stack
          direction='row'
          spacing={2}
          minWidth={'fit-content'}
          justifyContent='flex-end'
        >
          <FilterMenu
            label='Sort:'
            labelValue='Date Created'
            selectedValue={sortFilter}
            values={sortValues}
            onSelectValue={changeSortFilter}
          />
          <DatePicker dateState={dateState} setDateState={setDateState} />
        </Stack>
      </Stack>
      {loading && <LinearProgress />}

      {!loading && data && (
        <RepsTable
          currentPage={currentPage}
          size={size}
          sortBy={sortBy}
          setSize={setSize}
          setCurrentPage={setCurrentPage}
          setSortBy={setSortBy}
          data={data.getRepresentatives.data}
          openEditModal={openEditModal}
        />
      )}
      {!loading && !data && <NoDataView />}

      <AddRepModal open={addModal} setOpen={setAddModal} refetch={refetch} />
      <EditRepModal
        open={editModal}
        setOpen={setEditModal}
        setDeactivateModal={setDeactivateModal}
        setDeleteModal={setDeleteModal}
        repId={selectedRep}
        refetch={refetch}
      />

      <DeactivateRepModal
        open={deactivateModal}
        setOpen={setDeactivateModal}
        setEditModal={setEditModal}
        repId={selectedRep}
        refetch={refetch}
      />
      <DeleteRepModal
        open={deleteModal}
        setOpen={setDeleteModal}
        setEditModal={setEditModal}
        repId={selectedRep}
        refetch={refetch}
      />
    </Box>
  );
};
