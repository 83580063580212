import { Stack, Typography } from '@mui/material';
import { DataTable } from 'components/table';
import { DetailsLink, StatusChip } from 'components/table/cell-renderers';
import { RoutePath } from 'constants/routes-path';
import React, { Dispatch, SetStateAction } from 'react';
import { colors } from 'theme/color';
import { AllPartnerType } from 'types/data';
import { TableColumnType, TableRowType } from 'types/table';

type Props = {
  currentPage: number;
  size: number;
  sortBy: string;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setSize: Dispatch<SetStateAction<number>>;
  setSortBy: Dispatch<SetStateAction<string>>;
  data: AllPartnerType;
};

export const PartnerTable: React.FC<Props> = ({
  currentPage,
  setCurrentPage,
  setSize,
  size,
  setSortBy,
  sortBy,
  data,
}) => {
  const { partners = [], ...pageInfo } = data;
  const totalItems = pageInfo?.total_items || 0;

  const columns: TableColumnType[] = [
    {
      id: 'created_at',
      title: 'DATE CREATED',
      cellRenderer: ({ columnValue }) => (
        <Stack>
          <Typography variant='md' color={colors.black.light}>
            {new Date(columnValue).toLocaleDateString()}
          </Typography>
          <Typography variant='sm' color={colors.black.mid}>
            {new Date(columnValue).toLocaleTimeString('en-US', {
              hour: '2-digit',
              hour12: true,
              minute: '2-digit',
            })}
          </Typography>
        </Stack>
      ),
    },

    {
      id: 'email',
      title: 'Email',
    },
    {
      id: 'last_name',
      title: 'LAST NAME',
      cellRenderer: ({ values }) => (
        <Typography variant='md' color={colors.black.light}>
          {values.partner_name.split(' ')[1]}
        </Typography>
      ),
    },
    {
      id: 'first_name',
      title: 'FIRST NAME',
      cellRenderer: ({ values }) => (
        <Typography variant='md' color={colors.black.light}>
          {values.partner_name.split(' ')[0]}
        </Typography>
      ),
    },
    { id: 'partner_id', title: 'PARTNER ID' },
    { id: 'centre_name', title: 'CENTER NAME' },

    {
      id: 'status',
      title: 'STATUS',
      cellRenderer: ({ columnValue }) => (
        <StatusChip columnValue={columnValue === 'ACTIVE' ? true : false} />
      ),
    },
    {
      id: 'id',
      title: 'ACTIONS',
      cellRenderer: ({ columnValue }) => (
        <DetailsLink destination={`${RoutePath.PARTNERS}/${columnValue}`} />
      ),
    },
  ];
  return (
    <DataTable
      columns={columns}
      rows={partners as unknown as TableRowType[]}
      totalItems={totalItems}
      currentPage={currentPage}
      onPageChange={setCurrentPage}
      size={size}
      onSizeChange={setSize}
      setSortBy={setSortBy}
      sortBy={sortBy}
    />
  );
};
