import { ZodType, z } from 'zod';

export type SetTargetFieldType = {
  sales: string;
  period: string;
  category: string;
  product: string;
};

export const SetTargetSchema: ZodType<SetTargetFieldType> = z.object({
  sales: z.string(),
  period: z.string(),
  category: z.string(),
  product: z.string(),
});
