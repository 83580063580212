import { Box } from '@mui/material';
import { AuditTrailDetails } from 'components/audit-trail/details';

const AuditTrailDetailsPage = () => {
  return (
    <Box>
      <AuditTrailDetails />
    </Box>
  );
};

export default AuditTrailDetailsPage;
