import { Dispatch, SetStateAction, useState } from 'react';
import { DateRangePicker, Range } from 'react-date-range';

import { Stack } from '@mui/material';
import { colors } from 'theme/color';
import { DateFilterType } from 'types/data';
import { Button } from '../button';
import Modal from '../modal';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  dateState: DateFilterType;
  setDateState: Dispatch<SetStateAction<DateFilterType>>;
};

export const DateModal = ({ open, setOpen, dateState, setDateState }: Props) => {
  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const changeDate = () => {
    setOpen(false);
    setDateState({
      startDate: state[0].startDate,
      endDate: state[0].endDate,
    });
  };
  return (
    <Modal title='Select Date' open={open} setOpen={setOpen} maxWidth='md'>
      <Stack alignItems='center' spacing={4}>
        <DateRangePicker
          onChange={(item) => {
            console.log(item);
            setState([
              {
                ...item.selection,
              },
            ]);
          }}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction='horizontal'
          className='custom-calendar'
          fixedHeight
          rangeColors={[colors.secondary.light]}
        />
        <Button style={{ width: '250px', maxWidth: '100%' }} onClick={changeDate}>
          Apply
        </Button>
      </Stack>
    </Modal>
  );
};
