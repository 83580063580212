import { Stack } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import { PartnerType } from 'types/data';
import { BalanceCard } from './balance-card';

type Props = {
  partner: PartnerType;
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
};

export const BalanceInfo: React.FC<Props> = ({ partner, setTabIndex, tabIndex }) => {
  return (
    <Stack
      mb={4}
      spacing={4}
      direction='row'
      justifyContent='stretch'
      flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
      useFlexGap
    >
      {tabIndex === 0 && (
        <>
          <BalanceCard
            label='Wallet Balance'
            value={
              partner.centre_info
                ? partner.centre_info.reduce((a, b) => a + b.wallet_balance, 0)
                : 0
            }
          />
          <BalanceCard
            label='Credit Limit'
            value={
              partner.centre_info
                ? partner.centre_info.reduce((a, b) => a + b.credit_limit, 0)
                : 0
            }
          />
          <BalanceCard
            label='Credit Balance'
            value={
              partner.centre_info
                ? partner.centre_info.reduce((a, b) => a + b.credit_balance, 0)
                : 0
            }
          />
        </>
      )}
      {tabIndex === 1 && (
        <>
          <BalanceCard
            label='Total Representatives'
            value={
              partner.centre_info
                ? partner.centre_info
                    ?.map((item) => item.rep.length)
                    ?.reduce((a, b) => a + b, 0)
                : 0
            }
            currency={false}
          />
          <BalanceCard
            label='Total Active Representatives'
            value={
              partner.centre_info
                ? partner.centre_info
                    ?.map((item) =>
                      item.rep.filter((repItem) => repItem.status === 'ACTIVE')
                    )
                    .flat().length
                : 0
            }
            currency={false}
          />
          <BalanceCard
            label='Total Inactive Representatives'
            value={
              partner.centre_info
                ? partner.centre_info
                    ?.map((item) =>
                      item.rep.filter((repItem) => repItem.status === 'INACTIVE')
                    )
                    .flat().length
                : 0
            }
            currency={false}
          />
        </>
      )}
    </Stack>
  );
};
