import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as TickSquare } from 'assets/svgs/tick-square.svg';
import { Button, FormInput } from 'components/shared';
import { Card } from 'components/shared/card';
import { FormSelect } from 'components/shared/select';
import { RoutePath } from 'constants/routes-path';
import { EditInventoryFieldType, EditInventorySchema } from 'schema/inventory.schema';
import { colors } from 'theme/color';

import { ProductInventoryType } from '../data';
import { ProductImagePicker } from './product-image-picker';

type Props = {
  product: ProductInventoryType;
};
export const EditInventoryForm: React.FC<Props> = ({ product }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<EditInventoryFieldType>({
    resolver: zodResolver(EditInventorySchema),
  });
  const navigate = useNavigate();

  const navigateToInventory = () => {
    navigate(RoutePath.INVENTORY);
  };

  const onSubmit = async (data: EditInventoryFieldType) => {
    console.log(data);
    alert('Updated. Check logs for data. Redirecting now');
    navigateToInventory();
  };
  return (
    <Box
      padding={4}
      bgcolor={colors.white}
      borderRadius={'16px'}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid2 container spacing={4}>
        <Grid2 xs={12} md={6} lg={7}>
          <FormInput
            label='Product Name'
            error={errors?.name}
            required
            placeholder='Enter product name'
            {...register('name')}
          />
          <FormInput
            label='Description'
            error={errors?.description}
            required
            placeholder='Enter product description'
            {...register('description')}
          />
          <Stack
            direction='row'
            spacing={3}
            useFlexGap
            mt={2}
            width={'100%'}
            flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
          >
            <FormSelect
              label='Partner Name'
              error={errors?.partnerName}
              required
              placeholder='Enter product partner name'
              {...register('partnerName')}
              containerWidth='100%'
              options={['DCC Oworonshoki', 'DCC Berger', 'DCC Surulere'].map((item) => ({
                label: item,
                value: item,
              }))}
            />
            <FormSelect
              {...register('partnerCategory')}
              label='Partner Category'
              error={errors?.partnerCategory}
              required
              placeholder='Enter product partner category'
              containerWidth='100%'
              options={['Category A', 'Category B', 'Category C'].map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Stack>
          <Stack
            direction='row'
            spacing={3}
            useFlexGap
            mt={2}
            width={'100%'}
            flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
          >
            <FormInput
              label='Partner Name'
              error={errors?.centerName}
              required
              placeholder='Enter product center name'
              {...register('centerName')}
              containerWidth='100%'
            />
            <FormInput
              label='Stock Quantity'
              error={errors?.quantity}
              required
              placeholder='Enter stock quantity'
              {...register('quantity')}
              containerWidth='100%'
            />
          </Stack>
          <Stack
            direction='row'
            spacing={3}
            useFlexGap
            mt={2}
            width={'100%'}
            flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
          >
            <FormInput
              label='Regular Price'
              error={errors?.price}
              required
              placeholder='Enter product regular price'
              {...register('price')}
              containerWidth='100%'
            />
            <Stack spacing={'1px'} useFlexGap width='100%'>
              <FormInput
                label='Sales Price'
                error={errors?.salesPrice}
                required
                placeholder='Enter product sales price'
                {...register('salesPrice')}
                containerWidth='100%'
              />
              <Stack
                direction='row'
                spacing={1}
                useFlexGap
                alignItems='flex-start'
                maxWidth={'100%'}
                flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
              >
                {/* Checkbox */}
                <FormControlLabel
                  control={<Checkbox defaultChecked checkedIcon={<TickSquare />} />}
                  label='Promo Expiry Date'
                  sx={{
                    minWidth: 'fit-content',
                  }}
                />
                <FormInput
                  label=''
                  error={errors?.promoExpiryDate}
                  required
                  type='date'
                  placeholder='Enter product expiry date'
                  {...register('promoExpiryDate')}
                  containerWidth='100%'
                  style={{
                    height: '35px',
                    marginTop: 0,
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            spacing={3}
            useFlexGap
            mt={2}
            width={'100%'}
            flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
          >
            <FormInput
              label='Partner Name'
              error={errors?.partnerName}
              required
              type='date'
              placeholder='Enter product creation date'
              {...register('dateAdded')}
              containerWidth='100%'
            />
            <FormInput
              label='Time'
              error={errors?.time}
              required
              type='time'
              placeholder='Enter product creation time'
              {...register('time')}
              containerWidth='100%'
            />
          </Stack>
        </Grid2>
        <Grid2 xs={12} md={6} lg={5}>
          <Stack spacing={3}>
            {/* Main Image */}
            <Card>
              <img
                src={product.image}
                style={{
                  width: '100%',
                  height: 445,
                  objectFit: 'cover',
                  maxWidth: '100%',
                }}
                alt='product'
              />
              <Box
                sx={{
                  '&:hover': {
                    backgroundColor: `${colors.black.main}60`,
                    '&>.edit-btn': {
                      display: 'flex !important',
                    },
                  },
                  transition: 'background-color 0.5s',
                  display: 'flex',
                  justifyContents: 'center',
                  alignItems: 'center',
                }}
                position='absolute'
                top={0}
                right={0}
                bottom={0}
                left={0}
              >
                <Box
                  className='edit-btn'
                  display='none'
                  width='100%'
                  justifyContent={'center'}
                >
                  <Button
                    variant='outlined'
                    style={{
                      // display: 'none',
                      backgroundColor: colors.white,
                      color: colors.black.main,
                      borderColor: 'transparent',
                      width: 'fit-content',
                    }}
                  >
                    EDIT IMAGE
                  </Button>
                </Box>
              </Box>
            </Card>
            {/* Additional Images */}
            <Stack paddingInline={2}>
              <Typography variant='md' fontWeight='500'>
                Additional Images
              </Typography>
              <ProductImagePicker />
              <ProductImagePicker />
            </Stack>
          </Stack>
        </Grid2>
      </Grid2>
      <Stack
        mt={10}
        justifyContent='flex-end'
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
      >
        <Button type='submit' style={{ width: '250px', maxWidth: '100%' }}>
          Update
        </Button>
        <Button
          style={{ width: '250px', maxWidth: '100%' }}
          onClick={navigateToInventory}
          variant='outlined'
        >
          Cancel
        </Button>
      </Stack>
    </Box>
  );
};
