import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';

import { Button, FormInput } from 'components/shared';
import { Modal } from 'components/shared/modal';
import { FormSelect } from 'components/shared/select';
import { SetTargetFieldType, SetTargetSchema } from 'schema/center.schema';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const SetTargetModal: React.FC<Props> = ({ open, setOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SetTargetFieldType>({
    resolver: zodResolver(SetTargetSchema),
  });

  const onSubmit = async (data: SetTargetFieldType) => {
    console.log(data);
    alert('Updated');
    setOpen(false);
  };
  return (
    <Modal title='Set Target' open={open} setOpen={setOpen}>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label='Number of Sales '
          error={errors?.sales}
          required
          placeholder='Enter number of sales '
          {...register('sales')}
        />
        <FormSelect
          label='Period'
          error={errors?.period}
          required
          placeholder='Select period ...'
          {...register('period')}
          containerWidth='100%'
          options={['A', 'B', 'C'].map((item) => ({
            label: item,
            value: item,
          }))}
        />
        <FormSelect
          label='Category '
          error={errors?.category}
          required
          placeholder='Select...'
          {...register('category')}
          containerWidth='100%'
          options={['A', 'B', 'C'].map((item) => ({
            label: item,
            value: item,
          }))}
        />
        <FormSelect
          label='Product'
          error={errors?.product}
          required
          placeholder='Select...'
          {...register('product')}
          containerWidth='100%'
          options={['A', 'B', 'C'].map((item) => ({
            label: item,
            value: item,
          }))}
        />

        <Button type='submit' style={{ marginTop: '50px' }}>
          Set Target
        </Button>
      </Box>
    </Modal>
  );
};
