import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const ErrorBoundaryPage = () => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' minHeight={400}>
        <Typography variant="md">
        The page you are looking for is not available 
        </Typography>
    </Box>
  )
}

export default ErrorBoundaryPage
