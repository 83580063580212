import { Box, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ChangeEvent, useCallback, useState } from 'react';

import { ReactComponent as GridIcon } from 'assets/svgs/grid.svg';
import { ReactComponent as ListIcon } from 'assets/svgs/list.svg';
import { FilterMenu, PageHeader, SearchInput } from 'components';
import { CenterCard } from 'components/centers/center-card';
import { Pagination } from 'components/shared/pagination';
import { colors } from 'theme/color';

import { centerFilters, centers } from './data';

export const Centers = () => {
  const [searchValue, setSearchValue] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const [viewStyle, setViewStyle] = useState<'grid' | 'list'>('grid');
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(10);

  const onPageChange = (value: number) => {
    setCurrentPage(value);
  };

  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const changeProductFilter = useCallback((value: string) => {
    setProductFilter(value);
  }, []);

  const changeViewStyle = (view: 'grid' | 'list') => {
    setViewStyle(view);
  };

  return (
    <Box>
      <PageHeader title='Centers' subtitle='Overview of specialized centers.' />
      <Stack
        mb={4}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        useFlexGap
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <SearchInput value={searchValue} onChange={changeSearchValue} />
        <Stack
          direction='row'
          spacing={2}
          minWidth={'fit-content'}
          justifyContent='flex-end'
        >
          <FilterMenu
            label='Filter by:'
            labelValue='All Centers'
            selectedValue={productFilter}
            values={centerFilters}
            onSelectValue={changeProductFilter}
          />
          <Stack direction='row' spacing={1} alignItems='center'>
            <GridIcon
              stroke={viewStyle === 'grid' ? colors.primary.main : colors.gray.main}
              cursor='pointer'
              onClick={() => changeViewStyle('grid')}
            />
            <ListIcon
              stroke={viewStyle === 'list' ? colors.primary.main : colors.gray.main}
              cursor='pointer'
              onClick={() => changeViewStyle('list')}
            />
          </Stack>
        </Stack>
      </Stack>
      <Grid2
        container
        spacing={viewStyle === 'grid' ? 2 : 3}
        direction={viewStyle === 'grid' ? 'row' : 'column'}
      >
        {centers.map((item) => (
          <Grid2
            xs={12}
            md={viewStyle === 'grid' ? 4 : 12}
            xl={viewStyle === 'grid' ? 12 / 5 : 12}
            key={item.id}
          >
            <CenterCard center={item} viewStyle={viewStyle} />
          </Grid2>
        ))}
      </Grid2>
      <Pagination
        page={currentPage}
        totalItems={100}
        count={10}
        size={size}
        onSizeChange={setSize}
        onChange={onPageChange}
      />
    </Box>
  );
};
