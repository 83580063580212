import { Box, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageHeader } from 'components/page-header';
import { Button } from 'components/shared';

import { centers } from '../data';
import { BalanceInfo } from './balance-info';
import { CenterInfo } from './center-info';
import { DataSection } from './data-section';
import { BalanceModal } from './modals/balance-modal';
import { SetTargetModal } from './modals/set-target-modal';

export const CenterDetails = () => {
  const [targetModal, setTargetModal] = useState(false);
  const [balanceModal, setBalanceModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  let { id } = useParams();

  const center = useMemo(() => {
    if (id) {
      return centers.find((item) => item.id.toString() === id);
    }
  }, [id]);

  if (!center) return <>Invalid Center</>;

  return (
    <Box>
      <PageHeader
        title={center.name}
        showBackButton
        controls={
          <Stack direction='row' spacing={1}>
            <Button variant='outlined' onClick={() => setTargetModal(true)}>
              Set Target
            </Button>
            <Button onClick={() => setBalanceModal(true)}>Upload Balance</Button>
          </Stack>
        }
      />
      <CenterInfo center={center} />
      <BalanceInfo center={center} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <DataSection center={center} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <BalanceModal open={balanceModal} setOpen={setBalanceModal} />
      <SetTargetModal open={targetModal} setOpen={setTargetModal} />
    </Box>
  );
};
