import { Box, FormLabel, Typography } from '@mui/material';
import { FieldError, Merge } from 'react-hook-form';
import Select, { GroupBase, Props, StylesConfig } from 'react-select';

import { colors } from 'theme/color';
import { typography } from 'theme/typography';

import { SelectOption } from './select-option';

type SelectProps = {
  label?: string;
  required?: boolean;
  watchValue?: string;
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  containerWidth?: string;
};

export const FormMultiSelect = <
  Option,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  label,
  watchValue,
  required = false,
  error,
  containerWidth = 'fit-contents',

  ...resProps
}: Props<Option, IsMulti, Group> & SelectProps) => {
  const selectStyles: StylesConfig<Option, IsMulti, Group> = {
    control: (base, state) => {
      return {
        ...base,
        borderRadius: 8,
        borderColor: !error?.message ? colors.secondary.mid : colors.red.main,
        minHeight: 45,
      };
    },
    container: (base, state) => ({
      ...base,
      marginBlock: 4,
      fontFamily: `${typography.fontFamily}`,
      '& input': {
        font: 'inherit',
      },
    }),

    indicatorSeparator: () => ({
      display: 'none',
    }),

    indicatorsContainer: (base) => ({
      ...base,
      paddingInline: 12,
    }),

    valueContainer: (base) => ({
      ...base,
      paddingInline: 12,
    }),

    option: (base, state) => ({
      ...base,
      backgroundColor: colors.white,
      color: colors.black.main,
      ':hover': {
        backgroundColor: colors.primary.faded,
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.gray.plain,
      textAlign: 'left',
      fontSize: 13,
      fontWeight: 400,
    }),

    menuList: (base) => ({
      ...base,
      boxShadow: `0 0.1rem 0.2rem 0.1rem ${colors.secondary.rgba}`,
    }),
  };

  return (
    <Box marginTop={2} width={containerWidth}>
      {label && (
        <FormLabel sx={{ fontWeight: 600, color: '' }} htmlFor={label}>
          {label}{' '}
          {required ? (
            <Box component='span' color={colors.red['main']}>
              *
            </Box>
          ) : null}
        </FormLabel>
      )}
      <Select<Option, IsMulti, Group>
        styles={selectStyles}
        components={{
          Option: SelectOption,
        }}
        id={label}
        hideSelectedOptions={false}
        backspaceRemovesValue
        noOptionsMessage={() => 'No option found at the moment'}
        {...resProps}
      />

      {error?.message && (
        <Typography variant='md' color={colors.red.main}>
          {error?.message}
        </Typography>
      )}
    </Box>
  );
};
