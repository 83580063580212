import { Box, Stack, Typography } from '@mui/material';
import { FilterMenu } from 'components/filter-menu';
import { sortDates } from 'components/partners/data';
import { SearchInput } from 'components/search-input';
import { Button } from 'components/shared';
import { DatePicker } from 'components/shared/date-picker';
import { ChangeEvent, useCallback, useState } from 'react';
import { Range } from 'react-date-range';
import { colors } from 'theme/color';
import { SampleTable } from '../sample-table';
import { OrderBreakdown } from './order-breakdown';

export const DetailsTable = () => {
  const [searchValue, setSearchValue] = useState('');
  const [sortFilter, setSortFilter] = useState('');
  const [dateState, setDateState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const changeSortFilter = useCallback((value: string) => {
    setSortFilter(value);
  }, []);
  return (
    <Box mt={3} bgcolor={colors.white} borderRadius={'30.68px'}>
      <Stack
        direction='row'
        gap={3}
        justifyContent='space-between'
        alignItems='center'
        p={2}
        flexWrap='wrap'
      >
        <Stack direction='row' alignItems='row' gap={2}>
          <Typography
            fontSize='25px'
            fontWeight={600}
            style={{
              minWidth: 'fit-content',
            }}
            color={colors.black.main}
          >
            Order Details
          </Typography>
          <SearchInput
            value={searchValue}
            onChange={changeSearchValue}
            placeholder='Search by order number'
            width='100%'
            disableMargin
          />
        </Stack>
        <Stack direction='row' gap={2} flexWrap='wrap'>
          <FilterMenu
            label='Sort:'
            labelValue='Date Created'
            selectedValue={sortFilter}
            values={sortDates}
            onSelectValue={changeSortFilter}
          />
          <DatePicker dateState={dateState} setDateState={setDateState} />
          <Button
            style={{
              width: 'fit-content',
            }}
          >
            Lock Order
          </Button>
        </Stack>
      </Stack>
      <SampleTable />
      <OrderBreakdown />
    </Box>
  );
};
