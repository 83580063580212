import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';

import { Button, FormInput } from 'components/shared';
import { Modal } from 'components/shared/modal';
import { CreateRepFieldType, CreateRepSchema } from 'schema/rep.schema';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setDeactivateModal: Dispatch<SetStateAction<boolean>>;
  setDeleteModal: Dispatch<SetStateAction<boolean>>;
};

export const EditRepModal: React.FC<Props> = ({
  open,
  setOpen,
  setDeleteModal,
  setDeactivateModal,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateRepFieldType>({
    resolver: zodResolver(CreateRepSchema),
  });

  const onSubmit = async (data: CreateRepFieldType) => {
    console.log(data);
    alert('Updated');
    setOpen(false);
  };

  return (
    <Modal title='Representative Information' open={open} setOpen={setOpen} maxWidth='md'>
      <Stack alignItems='center' gap='10px' marginBottom={10}>
        <img
          src='https://picsum.photos/200'
          alt='rep'
          style={{
            width: 100,
            height: 100,
            objectFit: 'cover',
            borderRadius: 50,
          }}
        />
        <Typography variant='h3'>Fetuga Godswill</Typography>
        <Typography variant='md'>Representative ID: #156372</Typography>
        <Typography variant='md'>Mikestoney@gmail.com</Typography>
      </Stack>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label='First Name'
          error={errors?.firstName}
          required
          placeholder='Enter first Name'
          {...register('firstName')}
        />
        <FormInput
          label='Last Name'
          error={errors?.lastName}
          required
          placeholder='Enter last Name'
          {...register('lastName')}
        />

        <FormInput
          label='Center ID'
          error={errors?.centerID}
          required
          placeholder='Enter Center ID'
          {...register('centerID')}
        />

        <Stack direction='row' gap='10px' marginTop='50px'>
          <Button type='submit'>Edit Rep</Button>
          <Button
            type='button'
            variant='outlined'
            onClick={() => {
              setDeactivateModal(true);
              setOpen(false);
            }}
          >
            Deactivate Rep
          </Button>
          <Button
            type='button'
            variant='outlined'
            onClick={() => {
              setDeleteModal(true);
              setOpen(false);
            }}
          >
            Delete Rep
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
