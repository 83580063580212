import { useQuery } from '@apollo/client';

import { GET_USER } from 'graphql/queries/user.queries';

export const useGetUser = (id: string) => {
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id },
  });

  return {
    user: data?.user,
    loading,
    error,
  };
};