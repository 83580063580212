import loadable from '@loadable/component';
import { createBrowserRouter, Outlet } from 'react-router-dom';

import { Loader } from 'components';
import SetPasswordLayout from 'components/auth/set-password/layout';
import { RoutePath } from 'constants/routes-path';
import AuthLayout from 'pages/auth/layout';
import UpdateResetPasswordPage from 'pages/auth/update-reset-password';
import VerifyResetOTPPage from 'pages/auth/verify-reset-otp';
import AuditTrailPage from 'pages/dashboard/audit-trail';
import AuditTrailDetailsPage from 'pages/dashboard/audit-trail/details';
import CentersPage from 'pages/dashboard/centers';
import CentersDetailsPage from 'pages/dashboard/centers/details';
import DashboardLayout from 'pages/dashboard/layout';
import OrderManagementPage from 'pages/dashboard/order-management';
import OrderDetailsPage from 'pages/dashboard/order-management/details';
import PartnersPage from 'pages/dashboard/partners';
import PartnerDetailsPage from 'pages/dashboard/partners/details';
import RepsPage from 'pages/dashboard/reps';
import ErrorBoundaryPage from 'pages/error-boundary';
import { Suspense } from 'react';

const loadableOptions = {
  fallback: <Loader />,
};
// LAZY LOAD PAGES TO IMPROVE PERFORMANCE

//MAIN PAGES
const DashboardPage = loadable(() => import('pages/dashboard'), loadableOptions);
const InventoryPage = loadable(
  () => import('pages/dashboard/inventory'),
  loadableOptions
);
const TransactionsPage = loadable(
  () => import('pages/dashboard/transactions'),
  loadableOptions
);
const SignInPage = loadable(() => import('pages/auth/sign-in'), loadableOptions);
const SetPasswordPage = loadable(
  () => import('pages/auth/set-password'),
  loadableOptions
);
const ForgotPasswordPage = loadable(
  () => import('pages/auth/forgot-password'),
  loadableOptions
);

//PAGE DETAILS
const InventoryDetailPage = loadable(
  () => import('pages/dashboard/inventory/details'),
  loadableOptions
);

const SuspenseLayout = () => (
  <Suspense fallback={<Loader />}>
    <Outlet />
  </Suspense>
);

export const router = createBrowserRouter([
  {
    path: RoutePath.DASHBOARD,
    errorElement: (
      <DashboardLayout>
        <ErrorBoundaryPage />
      </DashboardLayout>
    ),
    children: [
      {
        element: <SuspenseLayout />,
        children: [
          {
            index: true,
            element: (
              <DashboardLayout>
                <DashboardPage />
              </DashboardLayout>
            ),
          },
          {
            path: RoutePath.INVENTORY,
            children: [
              {
                index: true,
                element: (
                  <DashboardLayout>
                    <InventoryPage />
                  </DashboardLayout>
                ),
              },
              {
                path: `${RoutePath.INVENTORY}/:id`,
                element: (
                  <DashboardLayout>
                    <InventoryDetailPage />
                  </DashboardLayout>
                ),
              },
            ],
          },
          {
            path: RoutePath.TRANSACTIONS,
            element: (
              <DashboardLayout>
                <TransactionsPage />
              </DashboardLayout>
            ),
          },
          {
            path: RoutePath.CENTERS,
            children: [
              {
                index: true,
                element: (
                  <DashboardLayout>
                    <CentersPage />
                  </DashboardLayout>
                ),
              },
              {
                path: `${RoutePath.CENTERS}/:id`,
                element: (
                  <DashboardLayout>
                    <CentersDetailsPage />
                  </DashboardLayout>
                ),
              },
            ],
          },
          {
            path: RoutePath.PARTNERS,
            children: [
              {
                index: true,
                element: (
                  <DashboardLayout>
                    <PartnersPage />
                  </DashboardLayout>
                ),
              },
              {
                path: `${RoutePath.PARTNERS}/:id`,
                element: (
                  <DashboardLayout>
                    <PartnerDetailsPage />
                  </DashboardLayout>
                ),
              },
            ],
          },
          {
            path: RoutePath.ORDER_MANAGEMENT,
            children: [
              {
                index: true,
                element: (
                  <DashboardLayout>
                    <OrderManagementPage />
                  </DashboardLayout>
                ),
              },
              {
                path: `${RoutePath.ORDER_MANAGEMENT}/:id`,
                element: (
                  <DashboardLayout>
                    <OrderDetailsPage />
                  </DashboardLayout>
                ),
              },
            ],
          },
          {
            path: RoutePath.REPS,
            element: (
              <DashboardLayout>
                <RepsPage />
              </DashboardLayout>
            ),
          },
          {
            path: RoutePath.AUDIT_TRAIL,
            children: [
              {
                index: true,
                element: (
                  <DashboardLayout>
                    <AuditTrailPage />
                  </DashboardLayout>
                ),
              },
              {
                path: `${RoutePath.AUDIT_TRAIL}/:id`,
                element: (
                  <DashboardLayout>
                    <AuditTrailDetailsPage />
                  </DashboardLayout>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: RoutePath.SIGN_IN,
    element: (
      <AuthLayout showBackButton={false}>
        <SignInPage />
      </AuthLayout>
    ),
  },
  {
    path: `${RoutePath.SET_PASSWORD}/:email`,
    element: (
      <SetPasswordLayout>
        <SetPasswordPage />
      </SetPasswordLayout>
    ),
  },
  {
    path: RoutePath.FORGOT_PASSWORD,
    element: (
      <AuthLayout>
        <ForgotPasswordPage />
      </AuthLayout>
    ),
  },
  {
    path: `${RoutePath.VERIFY_RESET_OTP}/:email`,
    element: (
      <AuthLayout>
        <VerifyResetOTPPage />
      </AuthLayout>
    ),
  },
  {
    path: `${RoutePath.UPDATE_RESET_PASSWORD}/:email`,
    element: (
      <AuthLayout>
        <UpdateResetPasswordPage />
      </AuthLayout>
    ),
  },
]);
