import { PageHeader } from 'components/page-header';
import { Button, Tabs } from 'components/shared';
import { useState } from 'react';
import { DataCenters } from './data-centers';
import { DataPartners } from './data-partners';
import { DataReps } from './data-reps';

export const AuditTrail = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <PageHeader
        title='Audit Trail'
        subtitle='Overview of activity log.'
        controls={<Button>Download</Button>}
      />
      <Tabs
        labels={['Centres', 'Partners', 'Representatives']}
        panels={[<DataCenters />, <DataPartners />, <DataReps />]}
        index={tabIndex}
        setIndex={setTabIndex}
      />
    </>
  );
};
