// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9FBFF;
  color: #696969;
}

a {
  text-decoration: none;
  color: inherit
}

/* Hide number input controls */
/* Chrome, Safari, Edge, Opera */
.disable-number-controls::-webkit-outer-spin-button,
.disable-number-controls::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.disable-number-controls[type=number] {
  -moz-appearance: textfield;
}

/* Calendar styles */
.custom-calendar {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,mCAAmC;EACnC,kCAAkC;EAClC,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB;AACF;;AAEA,+BAA+B;AAC/B,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;AAEA,oBAAoB;AACpB;EACE,WAAW;AACb","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #F9FBFF;\n  color: #696969;\n}\n\na {\n  text-decoration: none;\n  color: inherit\n}\n\n/* Hide number input controls */\n/* Chrome, Safari, Edge, Opera */\n.disable-number-controls::-webkit-outer-spin-button,\n.disable-number-controls::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\n.disable-number-controls[type=number] {\n  -moz-appearance: textfield;\n}\n\n/* Calendar styles */\n.custom-calendar {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
