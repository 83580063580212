import { Stack } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import { CenterType } from 'types/data';

import { BalanceCard } from './balance-card';

type Props = {
  center: CenterType;
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
};

export const BalanceInfo: React.FC<Props> = ({ center, setTabIndex, tabIndex }) => {
  return (
    <Stack
      mb={4}
      spacing={4}
      direction='row'
      justifyContent='stretch'
      flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
      useFlexGap
    >
      {tabIndex === 0 && (
        <>
          <BalanceCard label='Wallet Balance' value={center.wallet_balance} />
          <BalanceCard label='Credit Limit' value={center.credit_limit} />
          <BalanceCard label='Credit Balance' value={center.credit_balance} />
        </>
      )}
      {tabIndex === 1 && (
        <>
          <BalanceCard
            label='Total Representatives'
            value={center.rep?.length}
            currency={false}
          />
          <BalanceCard
            label='Total Active Representatives'
            value={center.rep?.filter((item) => item.status === 'ACTIVE')?.length || 0}
            currency={false}
          />
          <BalanceCard
            label='Total Inactive Representatives'
            value={center.rep?.filter((item) => item.status === 'INACTIVE')?.length || 0}
            currency={false}
          />
        </>
      )}
    </Stack>
  );
};
