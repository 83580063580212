import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography  from '@mui/material/Typography'

import BusinessPlan from 'assets/Images/business-plan.png'
import { ReactComponent as Logo } from 'assets/svgs/logo.svg'
import { PublicRoutes } from 'middleware'
import { colors } from 'theme/color'
import { RoutePath } from 'constants/routes-path'
import { ReactComponent as BackArrow } from 'assets/svgs/back-arrow.svg'

type Props={
  showBackButton?: boolean
  children:React.ReactNode
}

const AuthLayout = ({ children,showBackButton=true }:Props) => {
  return (
    <PublicRoutes>
      <Box
        width='40%'
        height='100vh'
        position='fixed'
        sx={{ background: colors.secondary['light'] }}>
        <Stack justifyContent='space-between'
          height='100%'
          paddingX={5} 
          paddingY={2}>
          <Link to={RoutePath.SIGN_IN}>
          <Logo width={100} /></Link>
           <Stack alignItems='center'>
            <img src={BusinessPlan} alt='business-plan' width='500px' />
           </Stack>
          <Typography color={colors.gray['main']}variant='h3' textAlign='center' paddingBottom={20}>Admin Portal</Typography>
        </Stack>
       </Box>

       {/* LAYOUT CONTENTS */}
      <Box display='flex'
         width='60%'
         position='relative'
         minHeight='100vh'
         marginLeft='40%'>

        {/* LAYOUT GO BACK BUTTON */}
        {showBackButton &&(
          <Box position='absolute' top='5%' right='5%'>
          <Link to={RoutePath.SIGN_IN}>
              <Box display='flex' alignItems='center' gap={1}>
               <BackArrow/> 
               <Typography color='#667085'>Back to log in
               </Typography>
             </Box>
          </Link>
           </Box> 
        )}
          
        {/* END*/}

        {/* LAYOUT FORM COMPONENTS */}
        <Box
         width='100%'
          maxWidth={800}
          marginX='auto'
          alignItems='center'
          justifyContent='center'>
          {children}
        </Box>
          {/* END */}
      </Box>
    </PublicRoutes>
  )
}

export default AuthLayout
