import { Stack, Typography } from '@mui/material';
import { DataTable } from 'components/table';
import { DetailsLink, StatusChip } from 'components/table/cell-renderers';
import React, { Dispatch, SetStateAction } from 'react';
import { colors } from 'theme/color';
import { AllRepsType, RepsType } from 'types/data';
import { TableColumnType, TableRowType } from 'types/table';

type Props = {
  currentPage: number;
  size: number;
  sortBy: string;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setSize: Dispatch<SetStateAction<number>>;
  setSortBy: Dispatch<SetStateAction<string>>;
  data: AllRepsType;
  openEditModal: (rep: RepsType) => void;
};

export const RepsTable: React.FC<Props> = ({
  currentPage,
  setCurrentPage,
  setSize,
  size,
  setSortBy,
  data,
  sortBy,
  openEditModal,
}) => {
  const { reps = [], ...pageInfo } = data;
  const totalItems = pageInfo?.total_items || 0;

  const columns: TableColumnType[] = [
    {
      id: 'id',
      title: 'S/N',
      cellRenderer: ({ index }) => <> {index + 1}</>,
    },

    {
      id: 'first_name',
      title: 'REP DETAILS',
      cellRenderer: ({ values }) => (
        <Stack>
          <Typography variant='md' color={colors.black.light}>
            {values.first_name + ' ' + values.last_name}
          </Typography>
          <Typography variant='sm' color={colors.black.mid}>
            {values.email}
          </Typography>
        </Stack>
      ),
    },
    {
      id: 'representative_id',
      title: 'REP ID',
      cellRenderer: ({ columnValue }) => (
        <Typography variant='md' color={colors.black.main}>
          {columnValue}
        </Typography>
      ),
    },
    { id: 'centre_name', title: 'CENTER NAME' },
    {
      id: 'created_at',
      title: 'DATE CREATED',
      cellRenderer: ({ columnValue }) => (
        <Stack>
          <Typography variant='md' color={colors.black.light}>
            {new Date(columnValue).toLocaleDateString()}
          </Typography>
          <Typography variant='sm' color={colors.black.mid}>
            {new Date(columnValue).toLocaleTimeString('en-US', {
              hour: '2-digit',
              hour12: true,
              minute: '2-digit',
            })}
          </Typography>
        </Stack>
      ),
    },
    {
      id: 'status',
      title: 'STATUS',
      cellRenderer: ({ columnValue }) => (
        <StatusChip columnValue={columnValue === 'ACTIVE' ? true : false} />
      ),
    },

    {
      id: 'representative_id',
      title: 'ACTIONS',
      cellRenderer: ({ values }) => (
        <DetailsLink onClick={() => openEditModal(values as RepsType)} destination='' />
      ),
    },
  ];
  return (
    <DataTable
      columns={columns}
      rows={reps as unknown as TableRowType[]}
      totalItems={totalItems}
      currentPage={currentPage}
      onPageChange={setCurrentPage}
      size={size}
      onSizeChange={setSize}
      setSortBy={setSortBy}
      sortBy={sortBy}
    />
  );
};
