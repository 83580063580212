import React from 'react';

import { Stack } from '@mui/material';
import { ReactComponent as BalanceIcon } from 'assets/svgs/balance.svg';
import { Typography } from 'components/shared';
import { Card } from 'components/shared/card';
import { Currency } from 'components/table/cell-renderers';
import { colors } from 'theme/color';
import { formatQuantity } from 'utils/table';

type Props = {
  label: string;
  value?: number;
  currency?: boolean;
};

export const BalanceCard: React.FC<Props> = ({ label, value, currency = true }) => {
  return (
    <Card sx={{ width: '100%' }}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <BalanceIcon width={50} />
        <Stack spacing='4px'>
          <Typography fontWeight={600} color={colors.black.light}>
            {label}
          </Typography>
          <Typography fontWeight={600} fontSize='18px'>
            {currency ? (
              <Currency columnValue={value || 0} />
            ) : (
              formatQuantity(value || 0)
            )}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
