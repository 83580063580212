import { Box, Stack } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { Range } from 'react-date-range';

import { ReactComponent as AddIcon } from 'assets/svgs/add.svg';
import { FilterMenu } from 'components/filter-menu';
import { PageHeader } from 'components/page-header';
import { sortDates } from 'components/partners/data';
import { SearchInput } from 'components/search-input';
import { Button } from 'components/shared';
import { DatePicker } from 'components/shared/date-picker';

import { AddRepModal } from './modals/add-rep-modal';
import { DeactivateRepModal } from './modals/deactivate-rep-modal';
import { DeleteRepModal } from './modals/delete-rep-modal';
import { EditRepModal } from './modals/edit-rep-modal';
import { SampleTable } from './sample-table';

export const AllReps = () => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortFilter, setSortFilter] = useState('');
  const [dateState, setDateState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const changeSortFilter = useCallback((value: string) => {
    setSortFilter(value);
  }, []);

  return (
    <Box>
      <PageHeader
        title='Representatives'
        subtitle='Overview of  representatives activities.'
        controls={
          <Button startIcon={<AddIcon />} onClick={() => setAddModal(true)}>
            Add Rep
          </Button>
        }
      />
      <Stack
        mb={4}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        useFlexGap
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <SearchInput value={searchValue} onChange={changeSearchValue} />
        <Stack
          direction='row'
          spacing={2}
          minWidth={'fit-content'}
          justifyContent='flex-end'
        >
          <FilterMenu
            label='Sort:'
            labelValue='Date Created'
            selectedValue={sortFilter}
            values={sortDates}
            onSelectValue={changeSortFilter}
          />
          <DatePicker dateState={dateState} setDateState={setDateState} />
        </Stack>
      </Stack>
      <SampleTable openEditModal={() => setEditModal(true)} />
      <AddRepModal open={addModal} setOpen={setAddModal} />
      <EditRepModal
        open={editModal}
        setOpen={setEditModal}
        setDeactivateModal={setDeactivateModal}
        setDeleteModal={setDeleteModal}
      />
      <DeactivateRepModal
        open={deactivateModal}
        setOpen={setDeactivateModal}
        setEditModal={setEditModal}
      />
      <DeleteRepModal
        open={deleteModal}
        setOpen={setDeleteModal}
        setEditModal={setEditModal}
      />
    </Box>
  );
};
