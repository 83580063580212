import { gql } from '@apollo/client';

export const CREATE_PARTNER = gql`
  mutation CREATE_PARTNER($createPartnerInput: CreatePartnerInput!) {
    createPartner(createPartnerInput: $createPartnerInput) {
      data {
        id
        partner_id
        partner_name
        email
        phone_number
        status
        is_first_time_login
        created_at
        updated_at
      }
      status
      message
      errors
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation UPDATE_PARTNER($input: UpdatePartnerInput, $id: String) {
    updatePartner(input: $input, id: $id) {
      data {
        id
        partner_id
        partner_name
        email
        phone_number
        status
        is_first_time_login
        created_at
        updated_at
      }
      status
      message
      errors
    }
  }
`;

export const DELETE_PARTNER = gql`
  mutation DELETE_PARTNER($id: ID!) {
    removePartner(id: $id) {
      data {
        id
      }
      status
      message
      errors
    }
  }
`;
