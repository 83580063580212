import { Stack, Typography } from '@mui/material';
import { DataTable } from 'components/table';
import { DetailsLink, StatusChip } from 'components/table/cell-renderers';
import { RoutePath } from 'constants/routes-path';
import { useState } from 'react';
import { colors } from 'theme/color';
import { formatTime } from 'utils/table';
import { sampleData } from './data';

export const SampleTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(10);
  const [sortBy, setSortBy] = useState('asc_createdAt');

  const onPageChange = (value) => setCurrentPage(value);

  const { pageInfo, nodes = [] } = sampleData.getAllVendors || {};
  const totalItems = pageInfo?.totalItems || 0;

  const columns = [
    {
      id: 'vendorName',
      title: 'Order ID',
      sortable: true,
      cellRenderer: ({ values }) => (
        <Stack>
          <Typography variant='md' color={colors.black.light}>
            {values.vendorName}
          </Typography>
          <Typography variant='sm' color={colors.black.mid}>
            {values.vendorType}
          </Typography>
        </Stack>
      ),
    },

    {
      id: 'state',
      title: 'Customer Name',
      cellRenderer: ({ columnValue }) => (
        <Typography variant='md' color={colors.secondary.light}>
          {columnValue}
        </Typography>
      ),
    },
    {
      id: 'createdAt',
      title: 'Total Order',
      cellRenderer: ({ columnValue }) => (
        <Stack>
          <Typography variant='md' color={colors.black.light}>
            {new Date(columnValue).toLocaleDateString()}
          </Typography>
          <Typography variant='sm' color={colors.black.mid}>
            {formatTime(columnValue)}
          </Typography>
        </Stack>
      ),
    },
    { id: 'city', title: 'Center Name' },

    {
      id: 'isActive',
      title: 'Active',
      cellRenderer: StatusChip,
    },
    {
      id: 'referenceId',
      title: 'Actions',
      cellRenderer: ({ columnValue }) => (
        <DetailsLink destination={`${RoutePath.ORDER_MANAGEMENT}/${columnValue}`} />
      ),
    },
  ];
  return (
    <DataTable
      columns={columns}
      rows={nodes}
      totalItems={totalItems}
      currentPage={currentPage}
      onPageChange={onPageChange}
      size={size}
      onSizeChange={setSize}
      setSortBy={setSortBy}
      sortBy={sortBy}
    />
  );
};
