import { TableSortLabel } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import SortIcon from 'assets/svgs/sort.svg';
import { TableColumnType } from 'types/table';
import { getSortOptions } from 'utils/table';

type Props = {
  setSortBy: Dispatch<SetStateAction<string>>;
  sortBy: string;
  column: TableColumnType;
};

export const SortableHeader: React.FC<Props> = ({ setSortBy, sortBy = '', column }) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const sortOn = column.sortName || column.id;

  useEffect(() => {
    const [sortOrder, sortField] = getSortOptions(sortBy);
    if (sortField === sortOn) setOrder(sortOrder as 'asc' | 'desc');
  }, [sortBy, sortOn]);

  const handleClick = () => {
    const value = order === 'asc' ? 'desc' : 'asc';
    setSortBy(`${value}_${sortOn}`);
    setOrder(value);
  };

  return (
    <>
      {column.sortable ? (
        <TableSortLabel
          active={sortBy.includes(sortOn)}
          direction={order || 'asc'}
          onClick={handleClick}
          IconComponent={() => <img src={SortIcon} alt='Sort' />}
          hideSortIcon
        >
          {column.title}
        </TableSortLabel>
      ) : (
        column.title
      )}
    </>
  );
};
