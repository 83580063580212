import Box from '@mui/material/Box';
import React from 'react';
import { useForm } from 'react-hook-form';

import { Button, FormInput } from 'components/shared';

import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { RoutePath } from 'constants/routes-path';
import { RESET_PASSWORD } from 'graphql/mutations/auth.mutations';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  UpdateResetPasswordFieldType,
  UpdateResetPasswordSchema,
} from 'schema/auth.schema';
import { sendCatchFeedback, sendFeedback } from 'utils/feedback';
import { FormCard } from '../card';

export const UpdateResetPassword: React.FC = () => {
  const navigate = useNavigate();
  let { email } = useParams();
  const location = useLocation();
  const userToken = location.state.token;

  const [requestReset, { loading }] = useMutation(RESET_PASSWORD);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UpdateResetPasswordFieldType>({
    resolver: zodResolver(UpdateResetPasswordSchema),
  });
  const password = watch('password');

  const onSubmit = async (data: UpdateResetPasswordFieldType) => {
    try {
      const response = await requestReset({
        variables: {
          email,
          password: data.password,
          token: userToken,
        },
      });

      sendFeedback(response.data?.resetAdminPassword?.message || 'Successful', 'success');
      navigate(RoutePath.SIGN_IN);
    } catch (error) {
      sendCatchFeedback(error);
    }
  };
  return (
    <FormCard
      title='Set new password'
      description='Your new password must be different to previously used passwords.'
    >
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label='New Password'
          type='password'
          hasPolicy
          watchValue={password}
          error={errors?.password}
          placeholder='Enter your new password'
          required
          {...register('password')}
        />

        <FormInput
          label='Confirm New Password'
          type='password'
          error={errors?.confirmPassword}
          placeholder='confirm your new password'
          required
          {...register('confirmPassword')}
        />
        <Button sx={{ marginTop: 4 }} loading={loading} type='submit'>
          Continue
        </Button>
      </Box>
    </FormCard>
  );
};
