import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Loader } from 'components/loader';
import { NoDataView } from 'components/shared/no-data-view';
import { GET_CENTER_REPS } from 'graphql/queries/center.queries';
import { CenterType, RepsType } from 'types/data';
import { sendCatchFeedback } from 'utils/feedback';
import { RepsTable } from './table';

type Props = {
  center: CenterType;
};
export const DataRepresentatives: React.FC<Props> = ({ center }) => {
  // const [searchValue, setSearchValue] = useState('');
  // const [orderFilter, setOrderFilter] = useState<SortFilterType>(orderStatuses[0]);
  const [getReps, { data, loading, error }] = useLazyQuery(GET_CENTER_REPS);
  const [reps, setReps] = useState<RepsType[] | undefined>(undefined);

  // const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
  //   setSearchValue(event.target.value);
  // };

  // const changeOrderFilter = (value: SortFilterType) => {
  //   setOrderFilter(value);
  // };

  useEffect(() => {
    if (center) {
      getReps({
        variables: {
          centreId: center.centre_id,
        },
      });
    }
  }, [center, getReps]);

  useEffect(() => {
    if (error) {
      sendCatchFeedback(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setReps(data.getCentreRepLists.data);
    }
  }, [data]);

  if (loading) return <Loader />;

  if (!reps) return <NoDataView />;
  return (
    <>
      {/* <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
        useFlexGap
        mb={4}
      >
        <SearchInput value={searchValue} onChange={changeSearchValue} />
        <FilterMenu
          label='Sort:'
          labelValue='All Status'
          selectedValue={orderFilter}
          values={orderStatuses}
          onSelectValue={changeOrderFilter}
        />
      </Stack> */}
      <RepsTable data={reps} />
    </>
  );
};
