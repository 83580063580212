import { Box, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { BackButton } from 'components/back-button';
import { colors } from 'theme/color';

type Props = {
  title: string;
  subtitle?: string;
  controls?: ReactNode;
  showBackButton?: boolean;
  backButtonRoute?: string;
};

export const PageHeader: FC<Props> = ({
  title,
  subtitle,
  controls,
  backButtonRoute,
  showBackButton = false,
}) => {
  return (
    <>
      {showBackButton && <BackButton navigateTo={backButtonRoute} />}
      <Stack
        justifyContent='space-between'
        alignItems={'center'}
        useFlexGap
        spacing={2}
        direction='row'
        flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
        mb={4}
      >
        <Stack spacing={1}>
          <Typography variant='h2'>{title}</Typography>
          {subtitle && (
            <Typography variant='lg' color={colors.black.light}>
              {subtitle}
            </Typography>
          )}
        </Stack>
        {controls && <Box>{controls}</Box>}
      </Stack>
    </>
  );
};
