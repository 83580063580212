import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from 'react';

import { ReactComponent as CloseIcon } from 'assets/svgs/close-circle.svg';
import { ReactComponent as ExcelIcon } from 'assets/svgs/excel.svg';
import { ReactComponent as UploadImage } from 'assets/svgs/upload-alt.svg';
import { Button } from 'components/shared';
import { Modal } from 'components/shared/modal';
import { colors } from 'theme/color';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const BalanceModal: React.FC<Props> = ({ open, setOpen }) => {
  const fileRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  const onFileChangeCapture = (e: ChangeEvent<HTMLInputElement>) => {
    /*Selected files data can be collected here.*/
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const onBtnClick = () => {
    fileRef.current?.click();
  };

  const resetFile = () => {
    setSelectedFile(undefined);
  };

  const onSubmit = async () => {
    console.log(selectedFile);
    alert('Updated');
    setOpen(false);
  };
  return (
    <Modal title='Upload Balance' open={open} setOpen={setOpen}>
      <Box component='form' onSubmit={onSubmit}>
        <Typography color={colors.black.light} variant='md'>
          Upload balance in CSV format.
        </Typography>
        <Stack
          mt={2}
          mb={1}
          p={2}
          border={`1px dashed ${colors.gray.plain}`}
          alignItems='center'
          textAlign='center'
          spacing={1}
          sx={{ cursor: 'pointer' }}
          onClick={onBtnClick}
        >
          <input
            type='file'
            ref={fileRef}
            onChangeCapture={onFileChangeCapture}
            hidden
            accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          />
          <UploadImage />
          <Typography color={colors.gray.plain} variant='md'>
            Drop your file here, or browse
          </Typography>
        </Stack>
        <Typography color={colors.black.light} variant='md'>
          Accepted format: CSV
        </Typography>
        {selectedFile && (
          <Stack
            mt={4}
            alignItems='center'
            justifyContent='space-between'
            direction='row'
            spacing={1}
            useFlexGap
            bgcolor={colors.neutral.main}
            px={2}
            py={1}
            borderRadius={'10px'}
          >
            <Stack direction='row' alignItems='center' spacing={1}>
              <ExcelIcon />
              <Typography color={colors.black.light} variant='md'>
                {selectedFile.name}
              </Typography>
            </Stack>
            <IconButton onClick={resetFile}>
              <CloseIcon />
            </IconButton>
          </Stack>
        )}

        <Button type='submit' style={{ marginTop: '50px' }}>
          Upload Balance
        </Button>
      </Box>
    </Modal>
  );
};
