import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Divider, List, ListItem, ListItemButton, Stack, Typography } from '@mui/material';

import { Icon } from 'components/shared/icons';
import { SIDEBAR } from 'constants/sidebar';
import { colors } from 'theme/color';
import { isActiveLink } from 'utils/navigation';

type ItemType={ 
  name: string,
   icon: string, 
   link?: string 
}

type SidebarSectionProps={ 
  title: string, 
  items:ItemType[] 
}

const SidebarSection: React.FC<SidebarSectionProps> = ({ title, items }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <ListItem sx={{ paddingX: 4, paddingBottom: 2, paddingTop: 2 }}>
        <Typography variant='xs'>{title}</Typography>
      </ListItem>
       {items.map(({ name, icon, link }, idx) => (
        <ListItem key={name} disablePadding sx={{
          paddingX: 2,
          color: colors.secondary['mid'],
          paddingY: 0.8,
          '&:last-of-type': {
            color: title === 'ADMIN' ? colors.red['main'] : ''
          },
        }}>
          <ListItemButton
            onClick={() => link ? navigate(link) : console.log('logout clicked')}
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: 2,
              gap: 1.5,
              background: isActiveLink({link,pathname}) ? colors.primary['mid'] : '',
              color: isActiveLink({link,pathname}) ? colors.primary['main'] : '',
              '&:hover': {
                background:title === 'ADMIN' && idx === 2 ? '': colors.primary['mid'],
                color: title === 'ADMIN' && idx === 2 ? '' : colors.primary['main'],
              }
            }}>
               <Box width={20}>
                 <Icon name={icon} />
               </Box>
            <Typography>{name}</Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
};

export const SidebarLinks: React.FC = () => {
  return (
    <Stack sx={{ height: 'calc(100% - 90px)', overflowY: 'auto' }}>
      <List disablePadding>
        <SidebarSection title="MAIN MENU" items={SIDEBAR.main} />
      </List>
      <List sx={{ paddingBottom: 5, marginTop: 'auto' }}>
        <Divider sx={{ borderColor: colors.secondary['mid'] }} />
        <SidebarSection title="ADMIN" items={SIDEBAR.admin} />
      </List>
    </Stack>
  );
};
