import { Box, Stack } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';

import { FilterMenu } from 'components/filter-menu';
import { sortValues } from 'components/partners/data';
import { SearchInput } from 'components/search-input';
import { Button, Typography } from 'components/shared';
import { DatePicker } from 'components/shared/date-picker';
import { colors } from 'theme/color';

import { DateFilterType, SortFilterType } from 'types/data';
import { SampleTable } from '../sample-table';
import { OrderBreakdown } from './order-breakdown';

export const DetailsTable = () => {
  const [searchValue, setSearchValue] = useState('');
  const [sortFilter, setSortFilter] = useState<SortFilterType>(sortValues[0]);
  const [dateState, setDateState] = useState<DateFilterType>({
    startDate: undefined,
    endDate: undefined,
  });
  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const changeSortFilter = useCallback((value: SortFilterType) => {
    setSortFilter(value);
  }, []);

  return (
    <Box mt={3} bgcolor={colors.white} borderRadius={'30.68px'}>
      <Stack
        direction='row'
        gap={3}
        justifyContent='space-between'
        alignItems='center'
        p={2}
        flexWrap='wrap'
      >
        <Stack direction='row' alignItems='center' gap={2}>
          <Typography
            variant='h1'
            fontWeight={600}
            style={{
              minWidth: 'fit-content',
            }}
            color={colors.black.main}
          >
            Order Details
          </Typography>
          <SearchInput
            value={searchValue}
            onChange={changeSearchValue}
            placeholder='Search by order number'
            width='100%'
          />
        </Stack>
        <Stack direction='row' gap={2} flexWrap='wrap'>
          <FilterMenu
            label='Sort:'
            labelValue='Date Created'
            selectedValue={sortFilter}
            values={sortValues}
            onSelectValue={changeSortFilter}
          />
          <DatePicker dateState={dateState} setDateState={setDateState} />
          <Button
            style={{
              width: 'fit-content',
            }}
          >
            Lock Order
          </Button>
        </Stack>
      </Stack>
      <SampleTable />
      <OrderBreakdown />
    </Box>
  );
};
