import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';

import { Button, FormInput } from 'components/shared';
import { Modal } from 'components/shared/modal';
import { AddCenterFieldType, AddCenterSchema } from 'schema/partner.schema';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const AddCenterModal: React.FC<Props> = ({ open, setOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AddCenterFieldType>({
    resolver: zodResolver(AddCenterSchema),
  });

  const onSubmit = async (data: AddCenterFieldType) => {
    console.log(data);
    alert('Updated');
    setOpen(false);
  };
  return (
    <Modal title='Add Center' open={open} setOpen={setOpen} maxWidth='sm'>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label='Centre Name'
          error={errors?.name}
          required
          placeholder='Enter centre name'
          {...register('name')}
        />
        <FormInput
          label='Centre Email'
          error={errors?.email}
          required
          placeholder='Enter centre email'
          {...register('email')}
        />
        <FormInput
          label='Centre Code'
          error={errors?.sapCode}
          required
          placeholder='Enter SAP code'
          {...register('sapCode')}
        />
        <FormInput
          label='Centre Address'
          error={errors?.address}
          required
          placeholder='Enter centre address'
          {...register('address')}
        />
        <FormInput
          label='Centre Location'
          error={errors?.location}
          required
          placeholder='Enter centre location'
          {...register('location')}
        />

        <Button type='submit' style={{ marginTop: '50px' }}>
          Add Center
        </Button>
      </Box>
    </Modal>
  );
};
