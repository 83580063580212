export const centerFilters = [
  { label: 'Date Created', value: 'created_at' },
  { label: 'Center Name', value: 'centre_name' },
];

export type CenterType = {
  id: number;
  image: string;
  name: string;
  activeRep: number;
  partner?: string;
  phone?: string;
  email?: string;
  address?: string;
  walletBalance?: number;
  creditLimit?: number;
  creditBalance?: number;
  totalRepresentatives?: number;
  totalActiveRepresentatives?: number;
  totalInactiveRepresentatives?: number;
};

export const centers: CenterType[] = [
  {
    id: 1,
    image: 'https://picsum.photos/200',
    name: 'DCC OWORONSHOKI',
    activeRep: 1,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 2,
    image: 'https://picsum.photos/200',
    name: 'DCC IKEJA',
    activeRep: 2,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 3,
    image: 'https://picsum.photos/200',
    name: 'SANDTEX STORE',
    activeRep: 3,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 4,
    image: 'https://picsum.photos/200',
    name: 'DCC IYANA OWORO',
    activeRep: 0,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 5,
    image: 'https://picsum.photos/200',
    name: 'DCC IYANA OWORO',
    activeRep: 0,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 6,
    image: 'https://picsum.photos/200',
    name: 'DCC GBAGADA',
    activeRep: 2,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 7,
    image: 'https://picsum.photos/200',
    name: 'DCC LEKKI',
    activeRep: 1,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
  {
    id: 8,
    image: 'https://picsum.photos/200',
    name: 'DCC OPEBI',
    activeRep: 1,
    partner: 'DCC',
    phone: '09182244561',
    email: 'admin@alerzo.com',
    address: 'Lagos, Nigeria',
    walletBalance: 200220002,
    creditLimit: 2000000022,
    creditBalance: 1230000,
    totalRepresentatives: 10,
    totalActiveRepresentatives: 5,
    totalInactiveRepresentatives: 5,
  },
];

export const orderStatuses = [
  { label: 'Completed', value: 'Completed' },
  { label: 'Partly Delivered', value: 'Partly Delivered' },
  { label: 'Cancelled', value: 'Cancelled' },
];
