import { Stack, Typography } from '@mui/material';
import { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';

import { ReactComponent as UploadImage } from 'assets/svgs/upload-image.svg';
import { colors } from 'theme/color';

export const ProductImagePicker = () => {
  const fileRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [previewURL, setPreviewURL] = useState('');

  const onFileChangeCapture = (e: ChangeEvent<HTMLInputElement>) => {
    /*Selected files data can be collected here.*/
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const onBtnClick = () => {
    fileRef.current?.click();
  };

  useEffect(() => {
    let objectUrl = '';
    if (selectedFile) {
      // create the preview
      objectUrl = URL.createObjectURL(selectedFile);
      setPreviewURL(objectUrl);
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <Stack
      mt={2}
      p={2}
      border={`1px dashed ${colors.gray.plain}`}
      alignItems='center'
      textAlign='center'
      spacing={1}
      sx={{ cursor: 'pointer' }}
    >
      <input type='file' ref={fileRef} onChangeCapture={onFileChangeCapture} hidden />
      {selectedFile ? (
        <img
          src={previewURL}
          alt='product'
          onClick={onBtnClick}
          width={130}
          height={130}
          style={{
            objectFit: 'cover',
          }}
        />
      ) : (
        <>
          <UploadImage onClick={onBtnClick} />
          <Typography color={colors.gray.plain} variant='md'>
            Drop your image here, or browse
            <br /> Jpeg, png are allowed
          </Typography>
        </>
      )}
    </Stack>
  );
};
