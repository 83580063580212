import { Checkbox, Stack, Typography } from '@mui/material';
import { components, GroupBase, OptionProps } from 'react-select';

import { ReactComponent as TickSquare } from 'assets/svgs/tick-square.svg';

export const SelectOption = <
  Option,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: OptionProps<Option, IsMulti, Group>
) => {
  return (
    <components.Option {...props}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
        useFlexGap
      >
        <Typography fontWeight={500}>{props.label}</Typography>
        <Checkbox checkedIcon={<TickSquare />} checked={props.isSelected} />
      </Stack>
    </components.Option>
  );
};
