import React from 'react'
import { Box, CircularProgress } from '@mui/material'

import { colors } from 'theme/color'

export const Loader:React.FC = () => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' height={400}>
      <CircularProgress sx={{color:colors.secondary['light']}}/>
    </Box>
  )
}

 
