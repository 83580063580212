import React, { Dispatch, SetStateAction } from 'react';

import { Tabs } from 'components/shared';

import { PartnerType } from 'types/data';
import { DataCenters } from './data-centers';
import { DataOrders } from './data-orders';

type Props = {
  tabIndex: number;
  partner: PartnerType;
  setTabIndex: Dispatch<SetStateAction<number>>;
};

export const DataSection: React.FC<Props> = ({ setTabIndex, tabIndex, partner }) => {
  return (
    <>
      <Tabs
        labels={['Centres', 'Orders']}
        panels={[<DataCenters partner={partner} />, <DataOrders partner={partner} />]}
        index={tabIndex}
        setIndex={setTabIndex}
      />
    </>
  );
};
