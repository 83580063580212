import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';

import decreaseIcon from 'assets/svgs/arrow_decrease.svg';
import increaseIcon from 'assets/svgs/arrow_increase.svg';
import { Card } from 'components/shared/card';
import { colors } from 'theme/color';

import { ProductInventoryType } from '../data';
import { InventoryMenu } from './inventory-menu';

type Props = {
  product: ProductInventoryType;
};
export const InventoryCard: React.FC<Props> = ({ product }) => {
  return (
    <Card>
      <Stack
        direction='row'
        alignItems='flex-start'
        justifyContent={'space-between'}
        spacing={1}
        mb={5}
      >
        <Stack direction='row' spacing={3} alignItems='flex-start'>
          <img
            src={product.image}
            alt='product'
            style={{
              objectFit: 'contain',
              width: 73,
              height: 73,
            }}
          />
          <Stack spacing={1}>
            <Typography variant='md' fontWeight={600}>
              {product.name}
            </Typography>
            <Typography variant='sm' color={colors.black.light} fontWeight={400}>
              {product.description}
            </Typography>
            <Typography variant='sm' color={colors.black.mid} fontWeight={600}>
              {product.category}
            </Typography>
            <Typography variant='md' fontWeight={700}>
              {product.price}
            </Typography>
          </Stack>
        </Stack>
        <InventoryMenu product={product} />
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
      >
        <Typography variant='md' fontWeight={500}>
          Sales
        </Typography>
        <Stack direction='row' alignItems={'center'} spacing={1}>
          <img
            src={product.salesIncrease ? increaseIcon : decreaseIcon}
            alt='sales direction'
          />
          <Typography
            variant='md'
            fontWeight={600}
            color={product.salesIncrease ? colors.green.main : colors.red.main}
          >
            {product.sales}
          </Typography>
        </Stack>
      </Stack>
      <Divider
        sx={{
          marginBlock: 1,
          opacity: 0.6,
        }}
      />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
      >
        <Typography variant='md' fontWeight={500}>
          Remaining Products
        </Typography>
        <Stack direction='row' alignItems={'center'} spacing={1}>
          <Box
            width='52px'
            height='4px'
            bgcolor={colors.neutral.mid}
            borderRadius={'8px'}
          >
            <Box
              width={(product.remainingProducts / product.totalInventory) * 100 + '%'}
              height='4px'
              bgcolor={!product.inventoryLow ? colors.green.main : colors.red.main}
              borderRadius={'8px'}
            ></Box>
          </Box>

          <Typography
            variant='md'
            fontWeight={600}
            color={!product.inventoryLow ? colors.green.main : colors.red.main}
          >
            {product.remainingProducts}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
