import { Stack, Typography } from '@mui/material';

import { colors } from 'theme/color';

export const OrderBreakdown = () => {
  return (
    <Stack alignItems='flex-end' padding={2}>
      <Stack width='300px' maxWidth='100%' gap={1}>
        <Stack
          justifyContent='space-between'
          alignItems='center'
          gap={10}
          direction='row'
        >
          <Typography>Subtotal</Typography>
          <Typography color={colors.black.main} fontWeight={600}>
            ₦50,050,000.00
          </Typography>
        </Stack>
        <Stack
          justifyContent='space-between'
          alignItems='center'
          gap={10}
          direction='row'
        >
          <Typography>Tax (20%)</Typography>
          <Typography color={colors.black.main} fontWeight={600}>
            ₦50,000.00
          </Typography>
        </Stack>
        <Stack
          justifyContent='space-between'
          alignItems='center'
          gap={10}
          direction='row'
        >
          <Typography>Shipping Rate</Typography>
          <Typography color={colors.black.main} fontWeight={600}>
            ₦16,000.00
          </Typography>
        </Stack>
        <Stack
          justifyContent='space-between'
          alignItems='center'
          gap={10}
          direction='row'
        >
          <Typography>Total</Typography>
          <Typography color={colors.black.main} fontWeight={600} variant='h2'>
            ₦50,050,000.00
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
