import React from 'react'
import {Typography as MuiTypography, TypographyProps} from '@mui/material'

type Props = TypographyProps & {
    children: React.ReactNode,
    noOfLines?:number
}

export const Typography:React.FC<Props> = ({ noOfLines, children, ...resProps}) => {
  return (
    <MuiTypography
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp:`${noOfLines}`,
      WebkitBoxOrient: 'vertical',
   }}
   {...resProps}
  >{children}</MuiTypography>
  )
}