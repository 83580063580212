import { Box, Stack } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { Range } from 'react-date-range';

import { ReactComponent as AddIcon } from 'assets/svgs/add.svg';
import { FilterMenu, PageHeader, SearchInput } from 'components';
import { Button } from 'components/shared';
import { DatePicker } from 'components/shared/date-picker';

import { AddPartnerModal } from './add-partner-modal';
import { sortDates } from './data';
import { SampleTable } from './sample-table';

export const Partners = () => {
  const [searchValue, setSearchValue] = useState('');
  const [sortFilter, setSortFilter] = useState('');
  const [dateState, setDateState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [addModal, setAddModal] = useState(false);

  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const changeSortFilter = useCallback((value: string) => {
    setSortFilter(value);
  }, []);

  return (
    <Box>
      <PageHeader
        title='Partners'
        subtitle='Overview of partner activities.'
        controls={
          <Button startIcon={<AddIcon />} onClick={() => setAddModal(true)}>
            Add Partner
          </Button>
        }
      />
      <Stack
        mb={4}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        useFlexGap
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <SearchInput value={searchValue} onChange={changeSearchValue} />
        <Stack
          direction='row'
          spacing={2}
          minWidth={'fit-content'}
          justifyContent='flex-end'
        >
          <FilterMenu
            label='Sort:'
            labelValue='Date Created'
            selectedValue={sortFilter}
            values={sortDates}
            onSelectValue={changeSortFilter}
          />
          <DatePicker dateState={dateState} setDateState={setDateState} />
        </Stack>
      </Stack>
      <SampleTable />
      <AddPartnerModal open={addModal} setOpen={setAddModal} />
    </Box>
  );
};
