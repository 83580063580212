import { Navigate } from 'react-router-dom';

import { RoutePath } from 'constants/routes-path';

const user=true;

export const PublicRoutes= ({children}:React.PropsWithChildren) => {
  return user ?<Navigate to={RoutePath.DASHBOARD} replace />:<>{children}</> 
};

export const ProtectedRoutes= ({children}:React.PropsWithChildren) => {
  return !user? <Navigate to={RoutePath.SIGN_IN} replace />:<>{children}</>

};
