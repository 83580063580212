import { gql } from '@apollo/client';

export const REFRESH_USER_TOKEN = gql`
  mutation RefreshUserToken($refreshToken: String!) {
    refreshUserToken(refreshToken: $refreshToken) {
      newAccessToken
    }
  }
`;

export const AUTHENTICATE_ADMIN = gql`
  mutation AuthenticateAdminUser($email: String!, $password: String!) {
    authenticateAdminUser(email: $email, password: $password) {
      data {
        _id
        phoneNumber
        email
        roleId
        isFirstTimeLogin
        createdAt
        updatedAt
      }
      status
      statusCode
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangeAdminPassword(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changeAdminPassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      data {
        _id
        phoneNumber
        email
        roleId
        isFirstTimeLogin
        createdAt
        updatedAt
      }
      status
      statusCode
      message
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestAdminPasswordReset($email: String!) {
    requestAdminPasswordReset(email: $email) {
      data {
        _id
        phoneNumber
        email
        roleId
        isFirstTimeLogin
        createdAt
        updatedAt
      }
      status
      statusCode
      message
    }
  }
`;

export const VALIDATE_RESET_PASSWORD_OTP = gql`
  mutation ValidateAdminPasswordResetRequest($email: String!, $otp: String!) {
    validateAdminPasswordResetRequest(email: $email, otp: $otp) {
      data 
      status
      statusCode
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetAdminPassword($token: String!, $email: String!, $password: String!) {
    resetAdminPassword(token: $token, email: $email, password: $password) {
      data {
        _id
        phoneNumber
        email
        roleId
        isFirstTimeLogin
        createdAt
        updatedAt
      }
      status
      statusCode
      message
    }
  }
`;
