import { Stack } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';

import { FilterMenu } from 'components/filter-menu';
import { sortValues } from 'components/partners/data';
import { SearchInput } from 'components/search-input';
import { DatePicker } from 'components/shared/date-picker';
import { DateFilterType, SortFilterType } from 'types/data';

import { SampleTable } from '../sample-table';

export const DataCenters = () => {
  const [searchValue, setSearchValue] = useState('');
  const [sortFilter, setSortFilter] = useState<SortFilterType>(sortValues[0]);
  const [dateState, setDateState] = useState<DateFilterType>({
    startDate: undefined,
    endDate: undefined,
  });
  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const changeSortFilter = useCallback((value: SortFilterType) => {
    setSortFilter(value);
  }, []);

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
        useFlexGap
        mb={4}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <SearchInput value={searchValue} onChange={changeSearchValue} />
        <Stack
          direction='row'
          spacing={2}
          minWidth={'fit-content'}
          justifyContent='flex-end'
        >
          <FilterMenu
            label='Sort:'
            labelValue='Date Created'
            selectedValue={sortFilter}
            values={sortValues}
            onSelectValue={changeSortFilter}
          />
          <DatePicker dateState={dateState} setDateState={setDateState} />
        </Stack>
      </Stack>
      <SampleTable />
    </>
  );
};
