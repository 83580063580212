import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import { ReactComponent as TickSquare } from 'assets/svgs/tick-square.svg'
import { ReactComponent as CloseSquare } from 'assets/svgs/close-square.svg'
import { PASSWORD_POLICY_PATTERN } from 'constants/patterns';
import { PasswordPolicyColor } from 'theme/color';
import { validator } from 'utils/validator';

type Props = {
    value: string
    error?: string
}

export const PasswordPolicy: React.FC<Props> = ({ value, error }) => {
    return (
        <Grid container >
            {PASSWORD_POLICY_PATTERN.map(({ text, pattern }, index) => (
                <Grid item xs={6} key={index}>
                    <Box display='flex' alignItems='center'>
                        <Checkbox checked={error || value ? true : false}
                            checkedIcon={validator({ value, pattern }) ? <TickSquare /> : <CloseSquare />} />
                        <Typography color={PasswordPolicyColor(validator({ value, pattern }), (value || error)??'')}>
                            {text}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    )
};

