import { ReactComponent as CancelledOrderIcon } from 'assets/svgs/cancelled-orders.svg';
import { ReactComponent as CompletedOrderIcon } from 'assets/svgs/completed-orders.svg';
import { ReactComponent as PendingOrderIcon } from 'assets/svgs/pending-orders.svg';
import { ReactComponent as ReturnedOrderIcon } from 'assets/svgs/returned-orders.svg';
import { ReactComponent as TotalOrderIcon } from 'assets/svgs/total-orders.svg';
import React from 'react';

export type AllowedStatuses =
  | 'all'
  | 'pending'
  | 'completed'
  | 'cancelled'
  | 'partly-delivered';

export type StatusTypes = {
  label: string;
  value: AllowedStatuses;
};

export const statuses: StatusTypes[] = [
  {
    label: 'All Orders',
    value: 'all',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
  {
    label: 'Partly Delivered',
    value: 'partly-delivered',
  },
];

export const sampleData = {
  getAllVendors: {
    pageInfo: {
      currentPage: 1,
      size: 50,
      totalItems: 2,
      __typename: 'Pages',
    },
    nodes: [
      {
        _id: '6501b44d025a47d5c46d546a',
        referenceId: 'VEN-AL-IBA-00002',
        vendorName: 'Bamise Olusegun',
        image: 'https://picsum.photos/300',
        vendorType: 'distributor',
        phoneNumber: '08067846661',
        state: 'Oyo',
        city: 'Ibadan Town',
        address: 'CMS building, 102 Agbeni road, Amunigun, Ibadan ',
        isActive: false,
        createdAt: new Date(),
        walletBalance: 200220002,
        creditLimit: 2000000022,
        creditBalance: 1230000,
        totalRepresentatives: 10,
        totalActiveRepresentatives: 5,
        totalInactiveRepresentatives: 5,
        __typename: 'Vendor',
      },
      {
        _id: '61e8ccab32287ce1678902ff',
        referenceId: 'VEN-AL-IBA-00001',
        vendorName: 'TestP_vendor',
        image: 'https://picsum.photos/300',
        vendorType: 'manufacturer',
        phoneNumber: '08111111199',
        state: 'Oyo State',
        city: 'Ibadan Town',
        address: 'test',
        isActive: true,
        createdAt: new Date(),
        walletBalance: 200220002,
        creditLimit: 2000000022,
        creditBalance: 1230000,
        totalRepresentatives: 10,
        totalActiveRepresentatives: 5,
        totalInactiveRepresentatives: 5,
        __typename: 'Vendor',
      },
    ],
    __typename: 'VendorResponse',
  },
};

export type SummaryDataType = {
  icon: React.ReactNode;
  label: string;
  value: number;
  percentageValue: number;
  percentageIncrease: boolean;
};

export const summaryData: SummaryDataType[] = [
  {
    icon: <TotalOrderIcon />,
    label: 'Total Orders',
    value: 40000000,
    percentageValue: 24,
    percentageIncrease: false,
  },
  {
    icon: <CompletedOrderIcon />,
    label: 'Total Completed Orders',
    value: 38000000,
    percentageValue: 10,
    percentageIncrease: true,
  },
  {
    icon: <PendingOrderIcon />,
    label: 'Total Pending Orders',
    value: 254,
    percentageValue: 45,
    percentageIncrease: true,
  },
  {
    icon: <CancelledOrderIcon />,
    label: 'Total Cancelled Orders',
    value: 109,
    percentageValue: 12,
    percentageIncrease: false,
  },
  {
    icon: <ReturnedOrderIcon />,
    label: 'Total Returned Orders',
    value: 19,
    percentageValue: 12,
    percentageIncrease: false,
  },
];
