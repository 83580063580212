import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import menuIcon from 'assets/svgs/menu.svg';
import { Switch } from 'components/shared';
import { RoutePath } from 'constants/routes-path';
import { colors } from 'theme/color';

import { ProductInventoryType } from '../data';

type Props = {
  product: ProductInventoryType;
};

export const InventoryMenu: React.FC<Props> = ({ product }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <IconButton
        aria-controls={open ? 'control-menu' : undefined}
        aria-haspopup='true'
        aria-label='options'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          width: '27.2px',
          height: '32.2px',
          backgroundColor: colors.neutral.mid,
          borderRadius: '4px',
        }}
      >
        <img src={menuIcon} alt='menu' />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <Stack spacing={4} alignItems='center' direction='row'>
            <Typography>Turn on out of stock</Typography>
            <Switch checked={product.inventoryLow} />
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <Typography>Lock inventory</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <Typography>Delete inventory</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <Link to={`${RoutePath.INVENTORY}/${product.id}`}>
            <Typography>Edit Inventory</Typography>
          </Link>
        </MenuItem>
      </Menu>
    </Box>
  );
};
