import { Box, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageHeader } from 'components/page-header';
import { Button } from 'components/shared';

import { sampleData } from '../data';
import { BalanceInfo } from './balance-info';
import { DataSection } from './data-section';
import { AddCenterModal } from './modals/add-center-modal';
import { EditPartnerModal } from './modals/edit-partner-modal';
import { PartnerInfo } from './partner-info';

export const PartnerDetails = () => {
  const [addCenterModal, setAddCenterModal] = useState(false);
  const [editPartnerModal, setEditPartnerModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  let { id } = useParams();

  const partner = useMemo(() => {
    if (id) {
      return sampleData.getAllVendors.nodes.find(
        (item) => item.referenceId === id
      ) as any;
    }
  }, [id]);

  if (!partner) return <>Invalid Partner</>;

  return (
    <Box>
      <PageHeader
        title={partner.vendorName + ' Details'}
        showBackButton
        controls={
          <Stack direction='row' spacing={1}>
            <Button onClick={() => setAddCenterModal(true)}>Add Center</Button>
            <Button onClick={() => setEditPartnerModal(true)} variant='outlined'>
              Edit Partner
            </Button>
            <Button onClick={() => null} variant='outlined'>
              Deactivate Partner
            </Button>
          </Stack>
        }
      />
      <PartnerInfo partner={partner} />
      <BalanceInfo partner={partner} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <DataSection tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <AddCenterModal open={addCenterModal} setOpen={setAddCenterModal} />
      <EditPartnerModal open={editPartnerModal} setOpen={setEditPartnerModal} />
    </Box>
  );
};
