import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as RightIcon } from 'assets/svgs/chevron-right.svg';
import { Card } from 'components/shared/card';
import { RoutePath } from 'constants/routes-path';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from 'theme/color';
import { CenterType } from '../data';

type Props = {
  viewStyle: 'grid' | 'list';
  center: CenterType;
};

export const CenterCard: React.FC<Props> = ({ center, viewStyle }) => {
  const hasActiveRep = center.activeRep >= 1;
  return (
    <Link to={`${RoutePath.CENTERS}/${center.id}`}>
      <Card>
        <Stack justifyContent={'space-between'} direction='row' alignItems='center'>
          <Stack direction={viewStyle === 'list' ? 'row' : 'column'} spacing={2}>
            <img
              src={center.image}
              alt='center'
              style={{
                width: 46.71,
                height: 46.71,
                borderRadius: 5.61,
              }}
            />
            <Stack spacing={1}>
              <Typography
                fontSize={20}
                fontWeight={700}
                style={{ wordWrap: 'break-word' }}
              >
                {center.name}
              </Typography>
              <Stack
                bgcolor={hasActiveRep ? colors.green.light : colors.black.plain}
                p={1}
                direction='row'
                spacing={1}
                borderRadius={'10px'}
                width='fit-content'
                alignItems='center'
              >
                <Box
                  width='6px'
                  height='6px'
                  sx={{
                    borderWidth: 3,
                    borderStyle: 'solid',
                  }}
                  bgcolor={hasActiveRep ? colors.green.main : colors.gray.faded}
                  borderColor={hasActiveRep ? colors.green.faded : colors.gray.plain}
                  borderRadius={'50%'}
                />
                <Typography color={hasActiveRep ? colors.green.main : colors.gray.faded}>
                  {hasActiveRep ? <>{center.activeRep} Active Rep</> : <>No Active Rep</>}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          {viewStyle === 'list' && <RightIcon />}
        </Stack>
      </Card>
    </Link>
  );
};
