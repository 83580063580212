//TODO: UPDATE WITH FIGMA FONT SIZES 

import { colors } from "./color";

export const typography = {
    fontSize: 12,
    fontFamily: 'Public Sans',
    h1: {
        fontSize: 26,
        fontWeight: 600,
        color:colors.black['main']
    },
    h2: {
        fontSize: 24,
        fontWeight: 600,
        color:colors.black['main']
        
    },
    h3: {
        fontSize: 18,
        fontWeight: 600,
        color:colors.black['main']
    },
    xl: {
        fontSize: 24,
        fontFamily: 'Public Sans',
        fontWeight: 400,
    },
    lg: {
        fontSize: 18,
        fontFamily: 'Public Sans',
        fontWeight: 400,
    },

    md: {
        fontSize:14,
        fontFamily: 'Public Sans',
        fontWeight: 400,
    },
    sm: {
    fontSize:12,
    fontFamily: 'Public Sans',
    fontWeight: 400,
    },
    xs: {
     fontSize:10,
     fontFamily: 'Public Sans',
     fontWeight: 400,
     },
}