import { Stack } from '@mui/material';
import { colors } from 'theme/color';
import { Typography } from '../typography';

export const NoDataView = () => {
  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      height='200px'
      width='100%'
      bgcolor={colors.white}
    >
      <Typography variant='md'>No data found</Typography>
    </Stack>
  );
};
