import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as RightIcon } from 'assets/svgs/chevron-right.svg';
import { Card } from 'components/shared/card';
import { RoutePath } from 'constants/routes-path';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from 'theme/color';
import { CenterType } from 'types/data';

type Props = {
  viewStyle: 'grid' | 'list';
  center: CenterType;
};

export const CenterCard: React.FC<Props> = ({ center, viewStyle }) => {
  const activeReps = center.rep?.filter((item) => item.status === 'ACTIVE');
  return (
    <Link to={`${RoutePath.CENTERS}/${center.id}`}>
      <Card>
        <Stack justifyContent={'space-between'} direction='row' alignItems='center'>
          <Stack direction={viewStyle === 'list' ? 'row' : 'column'} spacing={2}>
            {/* <img
              src={center.image}
              alt='center'
              style={{
                width: 46.71,
                height: 46.71,
                borderRadius: 5.61,
              }}
            /> */}
            <Stack spacing={1}>
              <Typography
                fontSize={16}
                fontWeight={700}
                style={{ wordWrap: 'break-word' }}
              >
                {center.centre_name}
              </Typography>
              <Stack
                bgcolor={activeReps.length > 0 ? colors.green.light : colors.black.plain}
                p={'4px'}
                direction='row'
                spacing={1}
                borderRadius={'4px'}
                width='fit-content'
                alignItems='center'
              >
                <Box
                  width='4px'
                  height='4px'
                  sx={{
                    borderWidth: 2,
                    borderStyle: 'solid',
                  }}
                  bgcolor={activeReps.length > 0 ? colors.green.main : colors.gray.faded}
                  borderColor={
                    activeReps.length > 0 ? colors.green.faded : colors.gray.plain
                  }
                  borderRadius={'50%'}
                />
                <Typography
                  color={activeReps.length > 0 ? colors.green.main : colors.gray.faded}
                  fontSize={12}
                >
                  {activeReps.length > 0 ? (
                    <>
                      {activeReps.length} Active {activeReps.length > 1 ? 'Reps' : 'Rep'}
                    </>
                  ) : (
                    <>No Active Rep</>
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          {viewStyle === 'list' && <RightIcon />}
        </Stack>
      </Card>
    </Link>
  );
};
