import React, { Dispatch, SetStateAction } from 'react';

import { Tabs } from 'components/shared';
import { CenterType } from 'types/data';

import { DataOrders } from './data-orders';
import { DataRepresentatives } from './data-representatives';

type Props = {
  center: CenterType;
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
};

export const DataSection: React.FC<Props> = ({ center, setTabIndex, tabIndex }) => {
  return (
    <>
      <Tabs
        labels={['Orders', 'Representative']}
        panels={[<DataOrders center={center} />, <DataRepresentatives center={center} />]}
        index={tabIndex}
        setIndex={setTabIndex}
      />
    </>
  );
};
