import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import { ReactComponent as CloseIcon } from 'assets/svgs/close-circle.svg';

type Props = {
  title: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
} & DialogProps;

export const Modal: React.FC<Props> = ({
  open,
  setOpen,
  title,
  children,
  maxWidth = 'md',
  ...rest
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      {...rest}
      onClose={handleClose}
      open={open}
      maxWidth={maxWidth}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px',
          mt: '50px',
        },
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, py: 4, px: 8 }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='h2'>{title}</Typography>
          <IconButton aria-label='close' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ p: 8 }}>{children}</DialogContent>
    </Dialog>
  );
};
