import { Stack, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

import { orderStatuses } from 'components/centers/data';
import { FilterMenu } from 'components/filter-menu';
import { SearchInput } from 'components/search-input';
import { SampleTable } from 'components/table/sample';
import { CenterType, SortFilterType } from 'types/data';

type Props = {
  center: CenterType;
};

export const DataOrders: React.FC<Props> = ({ center }) => {
  const [searchValue, setSearchValue] = useState('');
  const [orderFilter, setOrderFilter] = useState<SortFilterType>(orderStatuses[0]);

  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const changeOrderFilter = (value: SortFilterType) => {
    setOrderFilter(value);
  };
  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={4}
        gap={2}
        flexWrap='wrap'
      >
        <Typography variant='h3'>Purchased Products</Typography>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          spacing={4}
          useFlexGap
        >
          <SearchInput value={searchValue} onChange={changeSearchValue} width='100%' />
          <FilterMenu
            label='Sort:'
            labelValue='All Status'
            selectedValue={orderFilter}
            values={orderStatuses}
            onSelectValue={changeOrderFilter}
          />
        </Stack>
      </Stack>
      <SampleTable />
    </>
  );
};
