import { Box, LinearProgress, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as GridIcon } from 'assets/svgs/grid.svg';
import { ReactComponent as ListIcon } from 'assets/svgs/list.svg';
import { FilterMenu, PageHeader, SearchInput } from 'components';
import { CenterCard } from 'components/centers/center-card';
import { Pagination } from 'components/shared/pagination';
import { colors } from 'theme/color';

import { useLazyQuery } from '@apollo/client';
import { NoDataView } from 'components/shared/no-data-view';
import { GET_CENTERS } from 'graphql/queries/center.queries';
import { useDebounce } from 'hooks';
import { CenterType, SortFilterType } from 'types/data';
import { sendCatchFeedback } from 'utils/feedback';
import { centerFilters } from './data';

export const Centers = () => {
  const [searchValue, setSearchValue] = useState('');
  const [centerFilter, setCenterFilter] = useState(centerFilters[0]);
  const [viewStyle, setViewStyle] = useState<'grid' | 'list'>('grid');
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(10);
  const debouncedSearchTerm = useDebounce(searchValue, 1000);

  const [getCentres, { data, loading, error }] = useLazyQuery(GET_CENTERS);

  const centers = useMemo(() => data?.getCentres?.data?.centers, [data]);
  const totalItems = useMemo(() => data?.getCentres?.data?.total_items || 0, [data]);
  const numberPerPage = useMemo(() => data?.getCentres?.data?.per_page || 0, [data]);

  const onPageChange = (value: number) => {
    setCurrentPage(value);
  };

  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const changeCenterFilter = useCallback((value: SortFilterType) => {
    setCenterFilter(value);
  }, []);

  const changeViewStyle = (view: 'grid' | 'list') => {
    setViewStyle(view);
  };

  const getData = async () => {
    return await getCentres({
      variables: {
        page: currentPage,
        limit: size,
        search: debouncedSearchTerm,
        sortBy: centerFilter.value,
        sortOrder: 'DESC',
      },
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, centerFilter.value, currentPage, size]);

  useEffect(() => {
    if (error) {
      sendCatchFeedback(error);
    }
  }, [error]);
  return (
    <Box>
      <PageHeader title='Centers' subtitle='Overview of specialized centers.' />
      <Stack
        mb={4}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        useFlexGap
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <SearchInput value={searchValue} onChange={changeSearchValue} />
        <Stack
          direction='row'
          spacing={2}
          minWidth={'fit-content'}
          justifyContent='flex-end'
        >
          <FilterMenu
            label='Filter by:'
            labelValue='All Centers'
            selectedValue={centerFilter}
            values={centerFilters}
            onSelectValue={changeCenterFilter}
          />
          <Stack direction='row' spacing={1} alignItems='center'>
            <GridIcon
              stroke={viewStyle === 'grid' ? colors.primary.main : colors.gray.main}
              cursor='pointer'
              onClick={() => changeViewStyle('grid')}
            />
            <ListIcon
              stroke={viewStyle === 'list' ? colors.primary.main : colors.gray.main}
              cursor='pointer'
              onClick={() => changeViewStyle('list')}
            />
          </Stack>
        </Stack>
      </Stack>
      {loading && <LinearProgress />}
      {!loading && data && (
        <Grid2
          container
          spacing={viewStyle === 'grid' ? 2 : 3}
          direction={viewStyle === 'grid' ? 'row' : 'column'}
        >
          {centers?.map((item: CenterType) => (
            <Grid2
              xs={12}
              md={viewStyle === 'grid' ? 4 : 12}
              xl={viewStyle === 'grid' ? 12 / 5 : 12}
              key={item.id}
            >
              <CenterCard center={item} viewStyle={viewStyle} />
            </Grid2>
          ))}
        </Grid2>
      )}
      {!loading && !data && <NoDataView />}

      {!loading && data && (
        <Pagination
          page={currentPage}
          totalItems={totalItems}
          count={numberPerPage}
          size={size}
          onSizeChange={setSize}
          onChange={onPageChange}
        />
      )}
    </Box>
  );
};
