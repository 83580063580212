import { useState } from 'react';

import { DataTable } from '.';
import { PhoneNumber } from './cell-renderers';

const data = {
  getAllVendors: {
    pageInfo: {
      currentPage: 1,
      size: 50,
      totalItems: 2,
      __typename: 'Pages',
    },
    nodes: [
      {
        _id: '6501b44d025a47d5c46d546a',
        referenceId: 'VEN-AL-IBA-00002',
        vendorName: 'Bamise Olusegun',
        image: null,
        vendorType: 'distributor',
        phoneNumber: '08067846661',
        state: 'Oyo',
        city: 'Ibadan Town',
        address: 'CMS building, 102 Agbeni road, Amunigun, Ibadan ',
        isActive: false,
        createdAt: null,
        __typename: 'Vendor',
      },
      {
        _id: '61e8ccab32287ce1678902ff',
        referenceId: 'VEN-AL-IBA-00001',
        vendorName: 'TestP_vendor',
        image: null,
        vendorType: 'manufacturer',
        phoneNumber: '08111111199',
        state: 'Oyo State',
        city: 'Ibadan Town',
        address: 'test',
        isActive: true,
        createdAt: null,
        __typename: 'Vendor',
      },
    ],
    __typename: 'VendorResponse',
  },
};

export const SampleTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(10);
  const [sortBy, setSortBy] = useState('asc_createdAt');

  const onPageChange = (value) => setCurrentPage(value);

  const { pageInfo, nodes = [] } = data.getAllVendors || {};
  const totalItems = pageInfo?.totalItems || 0;

  const columns = [
    {
      id: 'vendorName',
      title: 'Vendor Name',
      sortable: true,
    },
    { id: 'referenceId', title: 'Vendor ID' },
    {
      id: 'vendorType',
      title: 'Vendor Type',
      cellRenderer: ({ columnValue }) => columnValue.toUpperCase(),
    },
    { id: 'phoneNumber', title: 'Phone Number', cellRenderer: PhoneNumber },
    { id: 'state', title: 'State' },
    { id: 'city', title: 'City' },
    { id: 'address', title: 'Address' },
    {
      id: 'isActive',
      title: 'Active',
      cellRenderer: ({ columnValue }) => (columnValue ? 'Yes' : 'No'),
    },
  ];
  return (
    <DataTable
      columns={columns}
      rows={nodes}
      totalItems={totalItems}
      currentPage={currentPage}
      onPageChange={onPageChange}
      size={size}
      onSizeChange={setSize}
      setSortBy={setSortBy}
      sortBy={sortBy}
    />
  );
};
