import React from 'react'
import { Box} from '@mui/material'

import ChatIcon from 'assets/svgs/chat.svg'
import BellIcon from 'assets/svgs/bell.svg'
import { Badge } from 'components/shared'
import { colors } from 'theme/color'

import { Breadcrumb } from './breadcrumb'

export const Header:React.FC = () => {
  return (
    <Box component="header" 
     sx={{
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      height:75, 
      position:'sticky',
      top: 0,
      background:colors.white,
      boxShadow:`1px 1px 0 0 ${colors.secondary['rgba']}`, paddingX:1
    }} 
    >
      <Breadcrumb/>
      <Box sx={{display:'flex', gap:2, paddingRight:1}}>
        <Badge count={1} label='Notifications' icon={BellIcon}/>
        <Badge count={1} label='Messages' icon={ChatIcon}/>
      </Box>
    </Box>
  )
}

 