import { Stack, Typography } from '@mui/material';
import { DataTable } from 'components/table';
import { DetailsLink, StatusChip } from 'components/table/cell-renderers';
import { RoutePath } from 'constants/routes-path';
import React from 'react';
import { colors } from 'theme/color';
import { RepsType } from 'types/data';
import { TableColumnType, TableRowType } from 'types/table';

type Props = {
  // currentPage: number;
  // size: number;
  // sortBy: string;
  // setCurrentPage: Dispatch<SetStateAction<number>>;
  // setSize: Dispatch<SetStateAction<number>>;
  // setSortBy: Dispatch<SetStateAction<string>>;
  data: RepsType[];
};

export const RepsTable: React.FC<Props> = ({
  // currentPage,
  // setCurrentPage,
  // setSize,
  // size,
  // setSortBy,
  // sortBy,
  data,
}) => {
  // const { partners = [], ...pageInfo } = data;
  // const totalItems = pageInfo?.total_items || 0;

  const columns: TableColumnType[] = [
    {
      id: 'representative_id',
      title: 'S/N',
      cellRenderer: ({ index }) => <> {index + 1}</>,
    },
    {
      id: 'first_name',
      title: 'Reps Details',
      cellRenderer: ({ columnValue, values }) => (
        <Stack>
          <Typography variant='md' color={colors.black.light}>
            {values.first_name + ' ' + values.last_name}
          </Typography>
          <Typography variant='sm' color={colors.black.mid}>
            {values.email}
          </Typography>
        </Stack>
      ),
    },

    {
      id: 'representative_id',
      title: 'REP ID',
    },
    {
      id: 'centre_name',
      title: 'Center name',
    },

    {
      id: 'created_at',
      title: 'DATE CREATED',
      cellRenderer: ({ columnValue }) => (
        <Stack>
          <Typography variant='md' color={colors.black.light}>
            {new Date(columnValue).toLocaleDateString()}
          </Typography>
          <Typography variant='sm' color={colors.black.mid}>
            {new Date(columnValue).toLocaleTimeString('en-US', {
              hour: '2-digit',
              hour12: true,
              minute: '2-digit',
            })}
          </Typography>
        </Stack>
      ),
    },
    {
      id: 'status',
      title: 'STATUS',
      cellRenderer: ({ columnValue }) => (
        <StatusChip columnValue={columnValue === 'ACTIVE' ? true : false} />
      ),
    },
    {
      id: 'id',
      title: 'ACTIONS',
      cellRenderer: ({ columnValue }) => (
        <DetailsLink destination={`${RoutePath.REPS}/?id=${columnValue}`} />
      ),
    },
  ];
  return (
    <DataTable
      columns={columns}
      rows={data as unknown as TableRowType[]}
      totalItems={data.length}
      showPagination={false}
      currentPage={1}
      onPageChange={() => null}
      // size={data.length}
      // onSizeChange={setSize}
      // setSortBy={setSortBy}
      // sortBy={sortBy}
    />
  );
};
