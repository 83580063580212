import { TableCell } from '@mui/material';
import React from 'react';

import { colors } from 'theme/color';
import { TableColumnType } from 'types/table';

import { Default } from '../cell-renderers';

type Props = {
  column: TableColumnType;
  values: any;
  columnValue: string | number;
  index: number;
  cellRenderer?: React.ReactNode;
};

export const TableCellRenderer: React.FC<Props> = ({
  column,
  values,
  columnValue,
  index,
  cellRenderer,
}) => {
  const commonProps = {
    sx: {
      fontSize: 14,
      color: colors.black.light,
      fontWeight: 400,
      padding: '16px',
      borderRight: `1px solid ${colors.gray.mid}`,
    },
  };
  if (cellRenderer) {
    return <TableCell {...commonProps}>{cellRenderer}</TableCell>;
  }
  if (column.cellRenderer) {
    const CellRenderer = column.cellRenderer;
    return (
      <TableCell {...commonProps}>
        <CellRenderer values={values} columnValue={columnValue} />
      </TableCell>
    );
  }
  return (
    <TableCell {...commonProps}>
      <Default columnValue={columnValue} />
    </TableCell>
  );
};
