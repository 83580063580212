import React from 'react'
import { ButtonProps, CircularProgress, Button as MUIButton} from '@mui/material'
import { colors } from 'theme/color'

type Props = {
    children: React.ReactNode,
    loading?:boolean
} & ButtonProps

export const Button: React.FC<Props> = ({ 
    loading,
    children,
    variant='contained', 
    sx,...resProps}) => {
    return (
        <MUIButton
            variant={variant} 
            disableElevation 
            fullWidth 
            sx={{
                height: 45,
                fontSize: 16, textTransform: 'capitalize',
                fontWeight: 600,
                borderRadius: '8px',
                minWidth: 'fit-content',
              ...sx
            }} 
            {...resProps}
        >
           {loading? <CircularProgress size={24} sx={{color:colors.white}} />: children}
        </MUIButton>
    )
}
