import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as CustomerIcon } from 'assets/svgs/customer-order.svg';
import { Button } from 'components/shared';
import React from 'react';
import { colors } from 'theme/color';

type Props = {
  orderDetails: any;
};

export const DetailControl: React.FC<Props> = ({ orderDetails }) => {
  return (
    <Stack
      gap={4}
      alignItems='stretch'
      direction='row'
      flexWrap={{
        xs: 'wrap',
        md: 'nowrap',
      }}
    >
      <Box
        style={{
          border: `1px solid ${colors.secondary.mid}`,
          borderRadius: 16,
          backgroundColor: colors.white,
          paddingInline: 24,
          paddingBlock: 16,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction='row' alignItems='center' spacing={2} useFlexGap mb={4}>
          <CustomerIcon />
          <Stack spacing={2}>
            <Typography variant='h3'>Customer</Typography>
            <Stack spacing={1}>
              <Typography variant='md' color={colors.black.light}>
                <b>Full name</b>: {orderDetails.vendorName}
              </Typography>
              <Typography variant='md' color={colors.black.light}>
                <b>Email</b>: {orderDetails.vendorType}
              </Typography>
              <Typography variant='md' color={colors.black.light}>
                <b>Phone</b>: {orderDetails.phoneNumber}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button variant='outlined'>View profile</Button>
      </Box>
      <Box
        style={{
          border: `1px solid ${colors.secondary.mid}`,
          borderRadius: 16,
          backgroundColor: colors.white,
          paddingInline: 24,
          paddingBlock: 16,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction='row' alignItems='center' spacing={2} useFlexGap mb={4}>
          <CustomerIcon />
          <Stack spacing={2}>
            <Typography variant='h3'>Customer</Typography>
            <Stack spacing={1}>
              <Typography variant='md' color={colors.black.light}>
                <b>Shipping</b>: Next express
              </Typography>
              <Typography variant='md' color={colors.black.light}>
                <b>Payment Method</b>: Paypal
              </Typography>
              <Typography variant='md' color={colors.black.light}>
                <b>Status</b>: pending
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button variant='outlined'>Download info</Button>
      </Box>
      <Box
        style={{
          border: `1px solid ${colors.secondary.mid}`,
          borderRadius: 16,
          backgroundColor: colors.white,
          paddingInline: 24,
          paddingBlock: 16,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction='row' alignItems='center' spacing={2} useFlexGap mb={4}>
          <CustomerIcon />
          <Stack spacing={2}>
            <Typography variant='h3'>Deliver to</Typography>
            <Stack spacing={1}>
              <Typography variant='md' color={colors.black.light}>
                <b>Address</b>: {orderDetails.address}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button
          variant='outlined'
          style={{
            marginTop: 'auto',
          }}
        >
          Edit Address
        </Button>
      </Box>
    </Stack>
  );
};
