
export const PASSWORD_POLICY_PATTERN = [
    {
        text: 'At least 8 characters',
        pattern: /^.{8,}$/,
    },
    {
        text: 'Contains at least one number',
        pattern: /[0-9]/,
    },
    {
        text: 'Contains a capital letter',
        pattern: /[A-Z]/,
    },
    {
        text: 'Contains a special character',
        pattern: /[!@#$%^&*]/,
    },
];

export const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_])[A-Za-z0-9!@#$%^&*_]{8,64}$/