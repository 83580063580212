import Box from '@mui/material/Box';
import { UpdateResetPassword } from 'components/auth/update-reset-password';

const UpdateResetPasswordPage = () => {
  return (
    <Box component='main'>
      <UpdateResetPassword />
    </Box>
  );
};

export default UpdateResetPasswordPage;
