import { Box, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { ReactComponent as DecreaseIcon } from 'assets/svgs/decrease-icon.svg';
import { ReactComponent as IncreaseIcon } from 'assets/svgs/increase-icon.svg';
import { Typography } from 'components/shared';
import { Quantity } from 'components/table/cell-renderers';
import { colors } from 'theme/color';

import { summaryData } from '../data';

export const OrderSummary = () => {
  return (
    <Box
      paddingBlock={3}
      bgcolor={colors.white}
      borderRadius='10px'
      width='100%'
      sx={{
        boxShadow: `0px 4px 36px 0px ${colors.secondary.rgba}`,
      }}
    >
      <Grid2
        container
        spacing={{
          xs: 4,
          lg: 0,
        }}
      >
        {summaryData.map((item, index) => (
          <Grid2 xs={12} md={4} lg={12 / 5} key={item.label}>
            <Stack
              spacing={1}
              useFlexGap
              paddingInline={3}
              sx={{
                borderRight: {
                  xs: 'none',
                  lg:
                    index < summaryData.length - 1
                      ? `1px solid ${colors.secondary.wooden}`
                      : 'none',
                },
              }}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                {item.icon}
                <Typography variant='lg' color={colors.black.light}>
                  {item.label}
                </Typography>
              </Stack>
              <Typography fontWeight={700} variant='h1' color={colors.black.main}>
                <Quantity columnValue={item.value} />
              </Typography>
              <Stack direction='row' alignItems='center' spacing='4px'>
                <Stack direction='row' alignItems='center' gap={'2px'}>
                  {item.percentageIncrease ? <IncreaseIcon /> : <DecreaseIcon />}
                  <Typography
                    variant='md'
                    color={item.percentageIncrease ? colors.green.main : colors.red.main}
                  >
                    {item.percentageValue}%
                  </Typography>
                </Stack>
                <Typography variant='md' color={colors.black.wooden}>
                  vs last Month
                </Typography>
              </Stack>
            </Stack>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};
