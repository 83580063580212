import Box from '@mui/material/Box';
import React from 'react';

import { Header, Sidebar } from 'components';
import { ProtectedRoutes } from 'middleware';

const DashboardLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <ProtectedRoutes>
      <Box>
        <Sidebar />
        <Box>
          <Box component='main' marginLeft='15rem'>
            <Header />
            <Box maxWidth={1400} minHeight='100vh' paddingX={2} marginX='auto'>
              <Box paddingY={1.5}>{children}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ProtectedRoutes>
  );
};

export default DashboardLayout;
