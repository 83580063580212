import { gql } from '@apollo/client';

export const GET_CENTERS = gql`
  query GET_CENTERS(
    $page: Int
    $limit: Int
    $search: String
    $filters: FilterCenters
    $sortBy: CentreSortBy
    $sortOrder: SortOrder
  ) {
    getCentres(
      page: $page
      limit: $limit
      search: $search
      filters: $filters
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        total_items
        per_page
        current_page
        page_count
        serial_no
        centers {
          id
          centre_id
          centre_name
          centre_address
          region
          business_name
          account_number
          serial_number
          credit_limit
          credit_balance
          wallet_balance
          total_balance
          rep {
            id
            first_name
            last_name
            email
            status
            representative_id
          }
          created_at
          updated_at
          state
        }
        has_previous_page
        has_next_page
      }
      status
      message
      errors
    }
  }
`;

export const GET_CENTER = gql`
  query GET_CENTER($id: ID!) {
    centre(id: $id) {
      data {
        id
        centre_id
        centre_name
        centre_address
        region
        business_name
        account_number
        serial_number
        credit_limit
        credit_balance
        wallet_balance
        total_balance
        rep {
          id
          serial_number
          first_name
          last_name
          email
          centre_id
          centre_name
          phone_number
          status
          representative_id
          created_at
          updated_at
        }
        created_at
        updated_at
        state
      }
    }
  }
`;

export const GET_CENTER_ACTIVE_REPS = gql`
  query GET_CENTER_ACTIVE_REPS($centre_id: String!) {
    getCentreActiveReps(centre_id: $centre_id) {
      centre_name
      total_active_reps
    }
  }
`;

export const GET_CENTER_REPS = gql`
  query GET_CENTER_REPS($centreId: String!) {
    getCentreRepLists(centreId: $centreId) {
      data {
        id
        first_name
        last_name
        email
        phone_number
        centre_id
        centre_name
        representative_id
        created_at
        status
      }
    }
  }
`;
