import { IconTypeMap, SvgIconTypeMap } from '@mui/material'

import { SvgIcon } from './svgs'

type Props = IconTypeMap['props'] &
  SvgIconTypeMap['props'] & {
    name: string
  } & {
    onClick?: (e: React.SyntheticEvent) => void
  }

  export const Icon: React.FC<Props> = ({ name, ...rest }) => {
      return <SvgIcon name={name} {...rest} />
}

  