import { ZodType, z } from 'zod';

export type EditInventoryFieldType = {
  name: string;
  description: string;
  partnerName: string;
  partnerCategory: string;
  centerName: string;
  quantity: string;
  price: string;
  salesPrice: string;
  promoExpiryDate: string;
  dateAdded: string;
  time: string;
};

export const EditInventorySchema: ZodType<EditInventoryFieldType> = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().min(1, 'Enter a description'),
  partnerName: z.string().min(1, 'Please specify partner name'),
  partnerCategory: z.string().min(1, 'Please specify partner category'),
  centerName: z.string().min(1, 'Please specify center name'),
  quantity: z.string().min(1, 'Enter quantity'),
  price: z.string().min(1, 'Price is required'),
  salesPrice: z.string().min(1, 'Enter sales price'),
  promoExpiryDate: z.string().min(1, 'Enter expiry date for promotion'),
  dateAdded: z.string().min(1, 'Specify the date inventory was added'),
  time: z.string().min(1, 'Specify the time inventory was added'),
});
