import { Stack, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as BalanceIcon } from 'assets/svgs/balance.svg';
import { Card } from 'components/shared/card';
import { Currency } from 'components/table/cell-renderers';
import { colors } from 'theme/color';
import { formatQuantity } from 'utils/table';

type Props = {
  label: string;
  value?: number;
  currency?: boolean;
};

export const BalanceCard: React.FC<Props> = ({ label, value, currency = true }) => {
  return (
    <Card sx={{ width: '100%' }}>
      <Stack direction='row' spacing={2}>
        <BalanceIcon />
        <Stack spacing={1}>
          <Typography fontWeight={600} color={colors.black.light}>
            {label}
          </Typography>
          <Typography fontWeight={600} fontSize='22px'>
            {currency ? (
              <Currency columnValue={value || 0} />
            ) : (
              formatQuantity(value || 0)
            )}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
