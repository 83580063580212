import { Dispatch, SetStateAction } from 'react';
import { DateRangePicker, Range } from 'react-date-range';

import { Stack } from '@mui/material';
import { colors } from 'theme/color';
import { Button } from '../button';
import { Modal } from '../modal';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  dateState: Range[];
  setDateState: Dispatch<SetStateAction<Range[]>>;
};

export const DateModal = ({ open, setOpen, dateState, setDateState }: Props) => {
  return (
    <Modal title='Select Date' open={open} setOpen={setOpen} maxWidth='lg'>
      <Stack alignItems='center' spacing={4}>
        <DateRangePicker
          onChange={(item) => setDateState([item.selection])}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dateState}
          direction='horizontal'
          className='custom-calendar'
          fixedHeight
          rangeColors={[colors.secondary.light]}
        />
        <Button
          style={{ width: '250px', maxWidth: '100%' }}
          onClick={() => setOpen(false)}
        >
          Apply
        </Button>
      </Stack>
    </Modal>
  );
};
