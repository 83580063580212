import React from 'react';
import { Link } from 'react-router-dom';
import Box  from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';

export const Breadcrumb:React.FC=()=>{

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Link  color="inherit" to="/" >
          Dashboard
        </Link>
        <Link
          color="inherit"
          to="/inventory"
        >
          Inventory
        </Link>
        <Link
          to="/"
          aria-current="page"
        >
          Breadcrumbs
        </Link>
      </Breadcrumbs>
    </Box>
  );
}
