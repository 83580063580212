export const  RoutePath= {
    DASHBOARD:"/",
    INVENTORY:"/inventory",
    PARTNERS:"/partners",
    ORDER_MANAGEMENT:"/order-management",
    TRANSACTIONS:"/transactions",
    REPS:"/reps",
    CENTERS:"/centers",
    REPORTS:"/reports",
    AUDIT_TRAIL:"/audit-trail",
    MESSAGES:"/messages",
    SETTINGS:"/settings",
    SIGN_IN:"/sign-in",
    SET_PASSWORD:"/set-password",
    FORGOT_PASSWORD:"/forgot-password",
  }  as const