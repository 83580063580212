import { Box, Stack } from '@mui/material';
import { lazy, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageHeader } from 'components/page-header';
import { Button } from 'components/shared';

import { useQuery } from '@apollo/client';
import { Loader } from 'components/loader';
import { GET_CENTER } from 'graphql/queries/center.queries';
import { CenterType } from 'types/data';
import { sendCatchFeedback } from 'utils/feedback';
import { BalanceInfo } from './balance-info';
import { CenterInfo } from './center-info';
import { DataSection } from './data-section';

// Lazy load modal
const BalanceModal = lazy(() => import('./modals/balance-modal'));
const SetTargetModal = lazy(() => import('./modals/set-target-modal'));

export const CenterDetails = () => {
  const [targetModal, setTargetModal] = useState(false);
  const [balanceModal, setBalanceModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  let { id } = useParams();
  const [center, setCenter] = useState<CenterType | undefined>(undefined);
  const { data, loading, error } = useQuery(GET_CENTER, {
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (error) {
      sendCatchFeedback(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setCenter(data.centre.data);
    }
  }, [data]);

  if (loading) return <Loader />;

  if (!center) return null;

  return (
    <Box>
      <PageHeader
        title={center.centre_name}
        showBackButton
        controls={
          <Stack direction='row' spacing={1}>
            <Button variant='outlined' onClick={() => setTargetModal(true)}>
              Set Target
            </Button>
            <Button onClick={() => setBalanceModal(true)}>Upload Balance</Button>
          </Stack>
        }
      />
      <CenterInfo center={center} />
      <BalanceInfo center={center} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <DataSection center={center} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <BalanceModal open={balanceModal} setOpen={setBalanceModal} />
      <SetTargetModal open={targetModal} setOpen={setTargetModal} />
    </Box>
  );
};
