import { Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import { ReactComponent as InfoIcon } from 'assets/svgs/info-circle.svg';
import { Button } from 'components/shared';
import { Modal } from 'components/shared/modal';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setEditModal: Dispatch<SetStateAction<boolean>>;
};

export const DeactivateRepModal: React.FC<Props> = ({ open, setOpen, setEditModal }) => {
  return (
    <Modal title='Representative Information' open={open} setOpen={setOpen} maxWidth='md'>
      <Stack alignItems='center' gap='10px' marginBottom={10}>
        <InfoIcon />
        <Typography variant='h3' fontSize='20px' fontWeight={500}>
          Deactivate Representative
        </Typography>
        <Typography variant='md'>
          Are you sure you want to deactivate this representative
        </Typography>
      </Stack>
      <Stack direction='row' gap='10px'>
        <Button
          onClick={() => {
            setEditModal(true);
            setOpen(false);
          }}
        >
          No, back
        </Button>
        <Button type='button' variant='outlined'>
          Yes, deactivate
        </Button>
      </Stack>
    </Modal>
  );
};
