import {
  Box,
  Pagination as MuiPagination,
  TablePagination,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { colors } from 'theme/color';

type Props = {
  size: number;
  onSizeChange: Dispatch<SetStateAction<number>>;
  page: number;
  count: number;
  totalItems: number;
  disabled?: boolean;
  onChange: (value: number) => void;
  hideCurrentPage?: boolean;
};

export const Pagination: React.FC<Props> = ({
  size,
  onSizeChange,
  page,
  totalItems,
  disabled = false,
  onChange,
  hideCurrentPage = false,
}) => {
  const maxPageNumber = Math.ceil(totalItems / size);

  const onPageChange = (event: any, page: number) => {
    onChange(page);
  };
  const onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const element = event?.target as HTMLButtonElement;
    const value = element.value;
    onSizeChange(Number(value));
    onChange(1);
  };

  const handlePageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const element = event?.target as HTMLButtonElement;
    const value = element.value;
    const page = +(value || '1');
    if (page <= maxPageNumber) {
      onChange(page);
    }
  };

  return (
    <Box
      position='relative'
      width='100%'
      bgcolor='transparent'
      paddingBlock={'20px'}
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexWrap='wrap'
    >
      <TablePagination
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        color='primary'
        disabled={disabled}
        rowsPerPageOptions={[10, 25, 50, 100, 200]}
        component='div'
        count={totalItems || 0}
        rowsPerPage={size}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        labelRowsPerPage='Show'
        ActionsComponent={() => (
          <MuiPagination
            page={page}
            count={maxPageNumber || 1}
            defaultPage={1}
            onChange={onPageChange}
            sx={{
              minWidth: 'fit-content',
            }}
          />
        )}
        labelDisplayedRows={() => null}
      />

      {!hideCurrentPage && (
        <Box display='flex' alignItems='center'>
          <Typography fontSize='14px' color={colors.gray.light} fontWeight={400}>
            Go to
          </Typography>
          <input
            min={1}
            max={maxPageNumber}
            className='disable-number-controls'
            value={page}
            type='number'
            onChange={handlePageInputChange}
            style={{
              border: `1px solid ${colors.neutral.light}`,
              marginLeft: 10,
              width: 60,
              height: 36,
              borderRadius: 30,
              backgroundColor: colors.white,
              textAlign: 'center',
              padding: ' 0!important',
              fontSize: 16,
              color: colors.black.light,
              MozAppearance: 'textfield',
            }}
          />
        </Box>
      )}
    </Box>
  );
};
