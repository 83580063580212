import React from 'react'
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'

import { colors } from 'theme/color'

export const SidebarHeader:React.FC = () => {
  return (
    <Box sx={{
        position:'sticky',
        top:0,
        zIndex: 1,
        background: colors.secondary['light'],  
      }}>
        <Box display='flex'
         paddingX={4} height={95} 
         alignItems='center' gap={1}>
        <Avatar>GR</Avatar>
        <Stack gap={0.5}>
        <Typography variant='md' color={colors.white}>
          Samuel Adeleke
        </Typography>
        <Typography variant='xs' color={colors.primary['main']}>
         Admin Account
        </Typography>
        </Stack>
          </Box>  
         <Divider sx={{ borderColor: colors.secondary['mid'] }} />
      </Box>
  )
}

