import { TableRow } from '@mui/material';
import React from 'react';

import { TableColumnType, TableRowType } from 'types/table';

import { TableCellRenderer } from '../table-cell-renderer';

type Props = {
  row: TableRowType;
  columns: TableColumnType[];
  index: number;
};

export const TableRowRenderer: React.FC<Props> = ({ row, columns, index }) => {
  if (row.rowRenderer) {
    const RowRenderer = row.rowRenderer;
    return (
      <TableRow>
        <RowRenderer row={row} columns={columns} />
      </TableRow>
    );
  }
  return (
    <TableRow>
      {columns.map(
        (column, index) =>
          !column.isHidden && (
            <TableCellRenderer
              column={column}
              key={index}
              index={index}
              values={row}
              columnValue={row[column.id as keyof TableRowType]}
            />
          )
      )}
    </TableRow>
  );
};
