import { gql } from '@apollo/client';

export const GET_REPS = gql`
  query GET_REPS(
    $page: Int
    $limit: Int
    $search: String
    $filters: FilterReps
    $sortBy: RepsSortBy
    $sortOrder: SortOrder
  ) {
    getRepresentatives(
      page: $page
      limit: $limit
      search: $search
      filters: $filters
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        total_items
        per_page
        current_page
        page_count
        serial_no
        reps {
          id
          serial_number
          first_name
          last_name
          email
          centre_id
          centre_name
          phone_number
          status
          representative_id
          created_at
          updated_at
          comment
        }
        has_previous_page
        has_next_page
      }
      status
      message
      errors
    }
  }
`;

export const GET_REP = gql`
  query GET_REP($id: ID!) {
    representative(id: $id) {
      data {
        id
        serial_number
        first_name
        last_name
        email
        centre_id
        centre_name
        phone_number
        status
        representative_id
        created_at
        updated_at
        comment
      }
    }
  }
`;
