import { ZodType, z } from 'zod';

export type CreatePartnerFieldType = {
  name: string;
  email: string;
  repEmail: string;
  sapCode: string;
  center: string[];
};

export const CreatePartnerSchema: ZodType<CreatePartnerFieldType> = z.object({
  name: z.string(),
  email: z.string(),
  repEmail: z.string(),
  sapCode: z.string(),
  center: z.array(z.string()),
});

export type AddCenterFieldType = {
  name: string;
  email: string;
  sapCode: string;
  address: string;
  location: string;
};

export const AddCenterSchema: ZodType<AddCenterFieldType> = z.object({
  name: z.string(),
  email: z.string(),
  sapCode: z.string(),
  address: z.string(),
  location: z.string(),
});
