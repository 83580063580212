import { Typography } from '@mui/material';
import React from 'react';

import { colors } from 'theme/color';

type Props = {
  label: string;
  value?: string | number;
};

export const InfoLabel: React.FC<Props> = ({ label, value }) => {
  return (
    <Typography variant='md' display='inline' color={colors.black.light}>
      {label}:{' '}
      <Typography display='inline' fontWeight={700} color={colors.black.main}>
        {value}
      </Typography>
    </Typography>
  );
};
