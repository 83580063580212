import { alpha, Switch as MuiSwitch, styled } from '@mui/material';
import React from 'react';

import { colors } from 'theme/color';

type Props = {
  checked: boolean;
};

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: colors.primary.main,
    '&:hover': {
      backgroundColor: alpha(colors.primary.main, 40),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colors.primary.main,
  },
}));

export const Switch: React.FC<Props> = ({ checked }) => {
  return <StyledSwitch size='small' checked={checked} />;
};
