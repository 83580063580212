import { gql } from '@apollo/client';

export const GET_PARTNERS = gql`
  query GET_PARTNERS(
    $page: Int
    $limit: Int
    $search: String
    $filters: FilterPartners
    $sortBy: PartnerSortBy
    $sortOrder: SortOrder
  ) {
    getPartners(
      page: $page
      limit: $limit
      search: $search
      filters: $filters
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        total_items
        per_page
        current_page
        page_count
        serial_no
        partners {
          id
          partner_name
          partner_id
          email
          centre_name
          status
          created_at
        }
        has_previous_page
        has_next_page
      }
      status
      message
      errors
    }
  }
`;

export const GET_PARTNER = gql`
  query GET_PARTNER($id: ID!) {
    getPartner(id: $id) {
      data {
        id
        partner_id
        partner_name
        email
        phone_number
        status
        is_first_time_login
        centre_info {
          id
          centre_id
          centre_name
          centre_address
          region
          business_name
          account_number
          serial_number
          credit_limit
          credit_balance
          wallet_balance
          total_balance
          state
          rep {
            id
            serial_number
            first_name
            last_name
            email
            centre_id
            centre_name
            phone_number
            status
            representative_id
            created_at
            updated_at
            comment
          }
          created_at
          updated_at
        }
        created_at
        updated_at
      }
      status
      message
      errors
    }
  }
`;
