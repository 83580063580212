import { Box, Stack } from '@mui/material';
import { lazy, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageHeader } from 'components/page-header';
import { Button } from 'components/shared';

import { useQuery } from '@apollo/client';
import { Loader } from 'components/loader';
import { GET_PARTNER } from 'graphql/queries/partner.queries';
import { PartnerType } from 'types/data';
import { sendCatchFeedback } from 'utils/feedback';
import { BalanceInfo } from './balance-info';
import { DataSection } from './data-section';
import DeletePartnerModal from './modals/delete-partner-modal';
import { PartnerInfo } from './partner-info';

// Lazy load modals
const AddCenterModal = lazy(() => import('./modals/add-center-modal'));
const EditPartnerModal = lazy(() => import('./modals/edit-partner-modal'));

export const PartnerDetails = () => {
  const [addCenterModal, setAddCenterModal] = useState(false);
  const [editPartnerModal, setEditPartnerModal] = useState(false);
  const [deletePartnerModal, setDeletePartnerModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  let { id } = useParams();

  const [partner, setPartner] = useState<PartnerType | undefined>(undefined);
  const { data, loading, error, refetch } = useQuery(GET_PARTNER, {
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (error) {
      sendCatchFeedback(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setPartner(data.getPartner.data);
    }
  }, [data]);

  if (loading) return <Loader />;

  if (!partner) return null;

  return (
    <Box>
      <PageHeader
        title={partner.partner_name + ' Details'}
        showBackButton
        controls={
          <Stack direction='row' spacing={1}>
            {/* <Button onClick={() => setAddCenterModal(true)}>Add Center</Button> */}
            <Button onClick={() => setEditPartnerModal(true)} variant='outlined'>
              Edit Partner
            </Button>
            <Button onClick={() => setDeletePartnerModal(true)} variant='outlined'>
              Remove Partner
            </Button>
          </Stack>
        }
      />
      <PartnerInfo partner={partner} />
      <BalanceInfo partner={partner} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <DataSection tabIndex={tabIndex} setTabIndex={setTabIndex} partner={partner} />
      <AddCenterModal open={addCenterModal} setOpen={setAddCenterModal} />
      <EditPartnerModal
        open={editPartnerModal}
        setOpen={setEditPartnerModal}
        refetch={refetch}
        partner={partner}
      />
      <DeletePartnerModal
        open={deletePartnerModal}
        setOpen={setDeletePartnerModal}
        partner={partner}
      />
    </Box>
  );
};
