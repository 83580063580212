import { ZodType, z } from 'zod';

export type EditInventoryFieldType = {
  name: string;
  description: string;
  partnerName: string;
  partnerCategory: string;
  centerName: string;
  quantity: string;
  price: string;
  salesPrice: string;
  promoExpiryDate: string;
  dateAdded: string;
  time: string;
};

export const EditInventorySchema: ZodType<EditInventoryFieldType> = z.object({
  name: z.string(),
  description: z.string(),
  partnerName: z.string(),
  partnerCategory: z.string(),
  centerName: z.string(),
  quantity: z.string(),
  price: z.string(),
  salesPrice: z.string(),
  promoExpiryDate: z.string(),
  dateAdded: z.string(),
  time: z.string(),
});
