import Box from '@mui/material/Box';
import React from 'react';
import { useForm } from 'react-hook-form';

import { Button, FormInput, Typography } from 'components/shared';

import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { RoutePath } from 'constants/routes-path';
import {
  REQUEST_PASSWORD_RESET,
  VALIDATE_RESET_PASSWORD_OTP,
} from 'graphql/mutations/auth.mutations';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmOTPFieldType, ConfirmOTPSchema } from 'schema/auth.schema';
import { colors } from 'theme/color';
import { sendCatchFeedback, sendFeedback } from 'utils/feedback';
import { FormCard } from '../card';

export const VerifyResetOTP: React.FC = () => {
  const navigate = useNavigate();
  let { email } = useParams();

  const [confirmOTP, { loading }] = useMutation(VALIDATE_RESET_PASSWORD_OTP);
  const [requestReset, { loading: requestLoading }] = useMutation(REQUEST_PASSWORD_RESET);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmOTPFieldType>({
    resolver: zodResolver(ConfirmOTPSchema),
  });

  const onSubmit = async (data: ConfirmOTPFieldType) => {
    try {
      const response = await confirmOTP({
        variables: {
          otp: data.otp,
          email,
        },
      });
      sendFeedback(
        response.data?.validateAdminPasswordResetRequest?.message || 'Successful',
        'success'
      );

      navigate(`${RoutePath.UPDATE_RESET_PASSWORD}/${email}`, {
        state: {
          token: response.data?.validateAdminPasswordResetRequest?.data[0],
        },
      });
    } catch (error) {
      sendCatchFeedback(error);
    }
  };

  const resendEmail = async () => {
    try {
      const response = await requestReset({
        variables: {
          email,
        },
      });
      if (response.data?.requestAdminPasswordReset?.statusCode !== 200) {
        return sendFeedback(
          response.data?.requestAdminPasswordReset?.message || 'Error',
          'error'
        );
      }
      sendFeedback(
        response.data?.requestAdminPasswordReset?.message || 'Successful',
        'success'
      );
    } catch (error) {
      sendCatchFeedback(error);
    }
  };
  return (
    <FormCard
      title='Confirm OTP'
      description='Enter the 6-digits code that was sent to your Mail'
    >
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label='Code'
          required
          error={errors?.otp}
          placeholder='Enter your 6-digit code'
          {...register('otp')}
        />
        <Stack direction='row' alignItems='center' gap='2px' mt={2}>
          <Typography>Didn't get the code?</Typography>
          <Button
            variant='text'
            style={{
              fontSize: '14px',
              color: colors.primary.main,
              width: 'fit-content',
            }}
            loading={requestLoading}
            onClick={resendEmail}
          >
            RESEND
          </Button>
        </Stack>
        <Button sx={{ marginTop: 8 }} loading={loading} type='submit'>
          Continue
        </Button>
      </Box>
    </FormCard>
  );
};
