import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';

import { Button, FormInput } from 'components/shared';
import { Modal } from 'components/shared/modal';
import { CreateRepFieldType, CreateRepSchema } from 'schema/rep.schema';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const AddRepModal: React.FC<Props> = ({ open, setOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateRepFieldType>({
    resolver: zodResolver(CreateRepSchema),
  });

  const onSubmit = async (data: CreateRepFieldType) => {
    console.log(data);
    alert('Updated');
    setOpen(false);
  };

  return (
    <Modal title='Create New Rep' open={open} setOpen={setOpen} maxWidth='md'>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label='First Name'
          error={errors?.firstName}
          required
          placeholder='Enter first Name'
          {...register('firstName')}
        />
        <FormInput
          label='Last Name'
          error={errors?.lastName}
          required
          placeholder='Enter last Name'
          {...register('lastName')}
        />
        <FormInput
          label='Email'
          error={errors?.email}
          type='email'
          required
          placeholder='Enter store email'
          {...register('email')}
        />
        <FormInput
          label='Center ID'
          error={errors?.centerID}
          required
          placeholder='Enter Center ID'
          {...register('centerID')}
        />

        <Button type='submit' style={{ marginTop: '50px' }}>
          Add Rep
        </Button>
      </Box>
    </Modal>
  );
};
