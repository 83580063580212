import { ZodType, z } from 'zod';

import { PASSWORD_PATTERN } from 'constants/patterns';
import { validator } from 'utils/validator';

export type SetPasswordFieldType = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export type SignInFieldType = {
  email: string;
  password: string;
};

export type ForgotPasswordFieldType = {
  email: string;
};

export type ConfirmOTPFieldType = {
  otp: string;
};

export type UpdateResetPasswordFieldType = {
  password: string;
  confirmPassword: string;
};

export const UserSignInSchema: ZodType<SignInFieldType> = z.object({
  email: z.string().email({ message: 'Email must be a valid email address' }),
  password: z
    .string()
    .min(8, { message: 'Passwords must be at least 8 characters long' }),
  // .refine((value: string) => validator({ value, pattern: PASSWORD_PATTERN }), {
  //   message:
  //     'Password should contain at least one lowercase letter, one uppercase letter, one number, and one special character',
  // }),
});

export const UserSetPassowrdSchema: ZodType<SetPasswordFieldType> = z
  .object({
    oldPassword: z.string(),
    password: z
      .string()
      .min(8, {
        message:
          'Password should contain at least one lowercase letter, one uppercase letter, one number, and one special character',
      })
      .refine((value: string) => validator({ value, pattern: PASSWORD_PATTERN }), {
        message: '',
      }),
    confirmPassword: z.string(),
  })
  .refine((schema) => schema.password === schema.confirmPassword, {
    message: 'Passwords and confirm password does not match',
    path: ['confirmPassword'],
  });

export const ForgotPasswordSchema: ZodType<ForgotPasswordFieldType> = z.object({
  email: z.string().email({ message: 'Email must be a valid email address' }),
});

export const ConfirmOTPSchema: ZodType<ConfirmOTPFieldType> = z.object({
  otp: z.string(),
});

export const UpdateResetPasswordSchema: ZodType<UpdateResetPasswordFieldType> = z
  .object({
    password: z
      .string()
      .min(8, {
        message:
          'Password should contain at least one lowercase letter, one uppercase letter, one number, and one special character',
      })
      .refine((value: string) => validator({ value, pattern: PASSWORD_PATTERN }), {
        message: '',
      }),
    confirmPassword: z.string(),
  })
  .refine((schema) => schema.password === schema.confirmPassword, {
    message: 'Passwords and confirm password does not match',
    path: ['confirmPassword'],
  });
