import { Box, Stack } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';

import { FilterMenu } from 'components/filter-menu';
import { PageHeader } from 'components/page-header';
import { sortValues } from 'components/partners/data';
import { SearchInput } from 'components/search-input';
import { DatePicker } from 'components/shared/date-picker';

import { DateFilterType, SortFilterType } from 'types/data';
import { AllowedStatuses } from './data';
import { SampleTable } from './sample-table';
import { StatusHandle } from './status-handle';
import { OrderSummary } from './summary/order-summary';

export const OrderManagement = () => {
  const [searchValue, setSearchValue] = useState('');
  const [sortFilter, setSortFilter] = useState<SortFilterType>(sortValues[0]);
  const [selectedStatus, setSelectedStatus] = useState<AllowedStatuses>('all');
  const [dateState, setDateState] = useState<DateFilterType>({
    startDate: undefined,
    endDate: undefined,
  });

  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const changeSortFilter = useCallback((value: SortFilterType) => {
    setSortFilter(value);
  }, []);

  return (
    <Box>
      <PageHeader
        title='Order Management'
        subtitle='Monitor the status of your orders in real-time.'
      />
      <OrderSummary />
      <Stack
        my={2}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        useFlexGap
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <SearchInput
          value={searchValue}
          onChange={changeSearchValue}
          placeholder='Search by order number '
        />
        <Stack
          direction='row'
          spacing={2}
          minWidth={'fit-content'}
          justifyContent='flex-end'
        >
          <FilterMenu
            label='Sort:'
            labelValue='Date Created'
            selectedValue={sortFilter}
            values={sortValues}
            onSelectValue={changeSortFilter}
          />
          <DatePicker dateState={dateState} setDateState={setDateState} />
        </Stack>
      </Stack>
      <StatusHandle
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
      <SampleTable />
    </Box>
  );
};
